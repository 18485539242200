import React, { useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios from "../services/axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./event-creation-context.scss";
import CreateUpdateNavigation from "../components/events/create_update_event/create_update_navigation/CreateUpdateNavigation";
import GeneralData from "../screens/events/create_update_event/GeneralData";
import Customization from "../screens/events/create_update_event/Customization";
import Tickets from "../screens/events/create_update_event/Tickets";
import Summary from "../screens/events/create_update_event/Summary";
import AuthContext from "./AuthContext";
import { create_ticket, update_ticket } from "helpers/events";
import { App } from "antd";
import { event_date_format } from "helpers/format";

const EventCreationContext = createContext();

function getDateOneMonthLater(hour = 16) {
	const currentDate = new Date();
	const nextMonth = new Date(currentDate);
	nextMonth.setMonth(currentDate.getMonth() + 1);
	nextMonth.setHours(hour, 0, 0, 0);
	return new Date(nextMonth);
}

const default_event = {
	id: null,
	title: "",
	organizer: "",
	small_description: "",
	description: "",
	start_at: getDateOneMonthLater(16),
	end_at: getDateOneMonthLater(18),
	full_date: event_date_format({ start_at: getDateOneMonthLater(16), end_at: getDateOneMonthLater(18) }),
	status: "",
	visibility: "",
	address_label: "",
	address_latitude: 0.0,
	address_longitude: 0.0,
	address_name: "",
	primary_color: "#000000",
	main_picture: "",
	tickets: [],
	participation_forms: [],
	main_picture_64: null,
	gallery_64: [],
	confirmation_page_message: "",
	email_pdf_message: "",
	email_pdf_email_response: "",
};

const all_true = (lst) => {
	for (let index = 0; index < lst.length; index++) {
		if (!lst[index]) return false;
	}
	return true;
};

export const EventCreationProvider = (props) => {
	const { user } = useContext(AuthContext);
	const event_data = useRef({ ...default_event, organizer: user.account.id });
	const base_data = useRef({ ...default_event, organizer: user.account.id });
	const [event, setEvent] = useState(event_data.current);
	const [loading, setLoading] = useState(false);
	const { event_id } = useParams();
	const navigate = useNavigate();
	const validation_data = useRef({
		general_informations: null,
		details: null,
		medias: null,
		date: null,
		location: null,
		customization: null,
		tickets: null,
		summary: null,
	});
	let [searchParams, setSearchParams] = useSearchParams();
	const [step, setStep] = useState({
		step:
			searchParams.get("step") &&
			!isNaN(searchParams.get("step")) &&
			parseInt(searchParams.get("step")) <= 4 &&
			parseInt(searchParams.get("step")) >= 0
				? parseInt(searchParams.get("step"))
				: 0,
		anchor: "",
	});
	const { notification } = App.useApp();

	const [validation, setValidation] = useState(validation_data.current);

	const update_event = (key, value) => {
		if (key === "general") {
			event_data.current = {
				...event_data.current,
				title: value.title,
				organizer: value.organizer,
				small_description: value.small_description,
				description: value.description,
				start_at: value.start_at,
				end_at: value.end_at,
				full_date: value.full_date,
				address_label: value.address_label,
				address_latitude: value.address_latitude,
				address_longitude: value.address_longitude,
				address_name: value.address_name,
				main_picture_64: value.main_picture_64,
				gallery_64: value.gallery_64,
			};
			validation_data.current = {
				...validation_data.current,
				general_informations: value.title != "",
				details: value.small_description != "" && value.description != "",
				medias: value.main_picture_64 != null || event_data.current.main_picture != "",
				date: value.start_at != "" && value.end_at != "",
				location: value.address_label != "" && value.address_latitude != 0 && value.address_longitude != 0,
			};
			setEvent(event_data.current);
			setValidation(validation_data.current);
		} else if (key === "customization") {
			event_data.current = {
				...event_data.current,
				primary_color: value.primary_color,
				confirmation_page_message: value.confirmation_page_message,
				email_pdf_message: value.email_pdf_message,
				email_pdf_email_response: value.email_pdf_email_response,
			};
			validation_data.current = {
				...validation_data.current,
				customization: value.primary_color !== "",
			};
			setEvent(event_data.current);
			setValidation(validation_data.current);
		} else if (key == "summary") {
			event_data.current = {
				...event_data.current,
				visibility: value.visibility,
				status: value.status,
			};
			setEvent(event_data.current);
		} else {
			if (key === "tickets") {
				const titles = value.map((elem) => elem.title != "" && elem.description != "");
				validation_data.current = {
					...validation_data.current,
					tickets: value.length != 0 && all_true(titles),
				};
				event_data.current = { ...event_data.current, tickets: value };
				setEvent(event_data.current);
				setValidation(validation_data.current);
			}
		}
	};

	const change_step = (next_step, anchor = "") => {
		setTimeout(() => {
			if (JSON.stringify(base_data.current) != JSON.stringify(event_data.current) || !event.id) {
				console.log("run save");
				continue_and_save(false, next_step);
			}
		}, 200);

		setStep({ ...step, step: next_step, anchor: anchor });
		setSearchParams({ step: next_step });
	};

	const continue_and_save = async (need_redirect = false, hard_step = -1) => {
		let id = event_data.current.id;

		if (!event_data.current.id) {
			id = await publish(true);
			navigate(`/update-event/${id}?step=${hard_step == -1 ? step.step : hard_step}`);
		} else {
			await publish();
			setSearchParams({ step: step.step + 1 });
			if (need_redirect) {
				setStep({ ...step, step: step.step + 1, anchor: "" });
			}
		}

		return id;
	};

	const publish_update_event = async (ask_publish) => {
		setLoading(true);

		let updated_event = await axios
			.put(`/api/events/${event_data.current.id}`, {
				id: event_data.current.id,
				organizer: event_data.current.organizer,
				title: event_data.current.title,
				small_description: event_data.current.small_description,
				description: event_data.current.description,
				main_picture: event_data.current.main_picture,
				primary_color: event_data.current.primary_color,
				start_at: new Date(event_data.current.start_at),
				end_at: new Date(event_data.current.end_at),
				full_date: event_data.current.full_date,
				address_label: event_data.current.address_label,
				status: ask_publish ? ask_publish : event.status,
				visibility: event.visibility,
				address_latitude: event_data.current.address_latitude,
				address_longitude: event_data.current.address_longitude,
				address_name: event_data.current.address_name,
				confirmation_page_message: event_data.current.confirmation_page_message,
				email_pdf_message: event_data.current.email_pdf_message,
				email_pdf_email_response: event_data.current.email_pdf_email_response,
			})
			.then((e) => e.data)
			.catch((e) => {
				if (e.response.status == 400) {
					notification.error({
						message: "Erreur lors de la modification",
						description: "Une erreur est survenue lors de la modification. Veuillez réessayer plus tard.",
						placement: "bottomLeft",
					});
				} else {
					notification.error({
						message: "Update error",
						description: "You do not have sufficient rights to perform this action",
						placement: "bottomLeft",
					});
				}
				return null;
			});
		if (updated_event) {
			for (let index = 0; index < event_data.current.tickets.length; index++) {
				const ticket = event_data.current.tickets[index];
				if (ticket.id && isNaN(ticket.id)) {
					delete ticket.id;
					const new_ticket = await create_ticket(updated_event.id, ticket, index);
					if (new_ticket) {
						ticket.id = new_ticket.id;
					}
				} else {
					if (ticket.need_update) {
						const updated_ticket = await update_ticket(updated_event.id, ticket, index);
					}
				}
			}

			if (event_data.current.main_picture_64) {
				var bodyFormData = new FormData();
				bodyFormData.append("main_picture", event_data.current.main_picture_64[0], "hello.png");

				const response = await axios
					.put(`/api/events/${event_data.current.id}/images`, bodyFormData, {
						headers: { "Content-Type": "multipart/form-data" },
						timeout: 20000,
					})
					.then((e) => e.data)
					.catch((e) => {
						console.log(e);
						return null;
					});
				if (response) {
					updated_event.main_picture = response;
				}
			}
		}
		setLoading(false);
		if (updated_event) set_event_data(updated_event);
		return updated_event ? updated_event.id : -1;
	};

	const publish = async (is_draft = false, ask_publish = false) => {
		if (event_id != undefined) {
			const id = await publish_update_event(ask_publish);
			return id;
		}
		setLoading(true);
		let new_event = await axios
			.post("/api/events", {
				organizer: user.account.id,
				title: event_data.current.title,
				small_description: event_data.current.small_description,
				description: event_data.current.description,
				main_picture: "",
				primary_color: event_data.current.primary_color,
				start_at: new Date(event_data.current.start_at),
				end_at: new Date(event_data.current.end_at),
				full_date: event_data.current.full_date,
				address_label: event_data.current.address_label,
				status: is_draft ? "draft" : "published",
				visibility: event_data.current.visibility,
				address_latitude: event_data.current.address_latitude,
				address_longitude: event_data.current.address_longitude,
				address_name: event_data.current.address_name,
				confirmation_page_message: event_data.current.confirmation_page_message,
				email_pdf_message: event_data.current.email_pdf_message,
				email_pdf_email_response: event_data.current.email_pdf_email_response,
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (new_event) {
			if (event_data.current.main_picture_64 && event_data.current.main_picture_64.length) {
				var bodyFormData = new FormData();
				bodyFormData.append("main_picture", event_data.current.main_picture_64[0], "hello.png");

				const response = await axios
					.put(`/api/events/${new_event.id}/images`, bodyFormData, {
						headers: { "Content-Type": "multipart/form-data" },
						timeout: 20000,
					})
					.then((e) => e.data)
					.catch((e) => {
						console.log(e);
						return null;
					});
				if (response) {
					new_event.main_picture = response;
				}
			}

			for (let index = 0; index < event_data.current.tickets.length; index++) {
				const ticket = event_data.current.tickets[index];
				const new_ticket = await create_ticket(new_event.id, ticket, index);
			}
		} else {
			notification.error({
				message: "Update error",
				description: "You do not have sufficient rights to perform this action",
				placement: "bottomLeft",
			});
		}
		setLoading(false);
		if (new_event) set_event_data(new_event);
		return new_event ? new_event.id : -1;
	};

	const set_event_data = (event) => {
		let tickets = event_data.current.tickets;

		if (event.tickets) {
			tickets = event.tickets;
		}

		event_data.current = {
			id: event.id,
			title: event.title,
			organizer: event.organizer,
			small_description: event.small_description,
			description: event.description,
			start_at: new Date(event.start_at),
			end_at: new Date(event.end_at),
			full_date: event.full_date,
			status: event.status,
			visibility: event.visibility,
			address_label: event.address_label,
			address_latitude: event.address_latitude,
			address_longitude: event.address_longitude,
			address_name: event.address_name,
			primary_color: event.primary_color,
			tickets: tickets,
			main_picture: event.main_picture,
			main_picture_64: null,
			gallery_64: [],
			loaded: true,
			participation_forms: event.participation_forms,
			confirmation_page_message: event.confirmation_page_message,
			email_pdf_message: event.email_pdf_message,
			email_pdf_email_response: event.email_pdf_email_response,
		};
		for (let index = 0; index < event_data.current.tickets.length; index++) {
			event_data.current.tickets[index].sell_start_at = event_data.current.tickets[index].sell_start_at
				? new Date(event_data.current.tickets[index].sell_start_at)
				: null;

			event_data.current.tickets[index].sell_end_at = event_data.current.tickets[index].sell_end_at
				? new Date(event_data.current.tickets[index].sell_end_at)
				: null;
			event_data.current.tickets[index].need_update = false;
		}

		base_data.current = JSON.parse(JSON.stringify(event_data.current));
		setEvent(event_data.current);
	};

	const load_existing_event = async () => {
		const event = await axios
			.get(`/api/events/${event_id}`)
			.then((e) => e.data)
			.catch((e) => null);

		if (event) {
			set_event_data(event);
			const ticket_title = event.tickets.map((elem) => elem.title != "" && elem.description != "");
			validation_data.current = {
				...validation_data.current,
				general_informations: event.title != "",
				details: event.small_description != "" && event.description != "",
				medias: event.main_picture != "",
				date: event.start_at != "" && event.end_at != "",
				location: event.address_label != "" && event.address_latitude != 0 && event.address_longitude != 0,
				customization: event.primary_color != "",
				tickets: event.tickets.length != 0 && all_true(ticket_title),
			};
			setValidation(validation_data.current);
			setStep({
				step:
					searchParams.get("step") &&
					!isNaN(searchParams.get("step")) &&
					parseInt(searchParams.get("step")) <= 4 &&
					parseInt(searchParams.get("step")) >= 0
						? parseInt(searchParams.get("step"))
						: 0,
				anchor: "",
			});
		}
	};

	useEffect(() => {
		if (step.anchor != "") {
			const element = document.getElementById(step.anchor);
			if (element) {
				const yOffset = -50; // adjust this value as needed

				const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

				window.scrollTo({ top: y, behavior: "smooth" });
			} else {
				window.scrollTo({ top: 0 });
			}
		}
	}, [step.anchor]);

	useEffect(() => {
		document.getElementById("aside-navbar").style.display = "none";
		document.getElementById("main-content").classList.add("no-padding-left");
		if (event_id != undefined) {
			load_existing_event();
		}
		return () => {
			document.getElementById("aside-navbar").style.display = "flex";
			document.getElementById("main-content").classList.remove("no-padding-left");
		};
	}, [event_id]);

	if (event_id != undefined && !event.loaded) {
		return null;
	}

	return (
		<EventCreationContext.Provider
			value={{
				event,
				update_event,
				change_step,
				validation,
				loading,
				publish,
				step,
				continue_and_save,
				base_data,
			}}
		>
			<div className="event-creation-container">
				<CreateUpdateNavigation validation={validation} step={step} change_step={change_step} />
				{step.step === 0 ? (
					<GeneralData />
				) : step.step === 1 ? (
					<Customization />
				) : step.step === 2 ? (
					<Tickets />
				) : (
					<Summary />
				)}
			</div>
		</EventCreationContext.Provider>
	);
};

export default EventCreationContext;
