import { Button, Col, Form, Input, Radio, Row } from "antd";
import DefaultView from "components/account_settings/default_view/DefaultView";
import LabelContent from "components/main/label_content/LabelContent";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { useForm } from "antd/es/form/Form";
import axios from "../../../services/axios";
import AuthContext from "contexts/AuthContext";

const NewFinancialData = () => {
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState(null);
	const [isValid, setIsValid] = useState(false);
	const [connectedAccountState, setConnectedAccountState] = useState("");
	const [form] = useForm();

	const { user } = useContext(AuthContext);

	const reset = () => {
		form.resetFields();
		setIsValid(false);
	};

	const save = () => {
		form.validateFields()
			.then((e) => form.submit())
			.catch((e) => console.log("error"));
	};

	const load_financial_data = async () => {
		const ret = await axios
			.get(`/api/organizers/${user.account.id}/connected-account`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			console.log(ret);
			setConnectedAccountState(ret);
		}
	};

	const generate_stripe_link = async () => {
		setLoading(true);
		const ret = await axios
			.post(`/api/organizers/${user.account.id}/connected-account`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			window.location.href = ret;
		}

		setLoading(false);
	};

	useEffect(() => {
		if (user?.account?.id) {
			load_financial_data();
		}
	}, [user]);

	return (
		<DefaultView loading={loading} save={save} reset={reset} is_valid={() => isValid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>Finances</h1>
						<p>La gestion des finances, des paiements et des factures est entièrement gérée par Stripe.</p>
					</div>
					<div className="setting-block">
						<div className="block-container full-width">
							<div className="setting-block-description full-width">
								<h2>Connexion avec Stripe</h2>
								{connectedAccountState.startsWith("valid") ? (
									<p>
										Votre compte stripe utilisant l'email{" "}
										<strong>{connectedAccountState.split("|")[1]}</strong> est lié à votre compte
										PassPass. Tout est en ordre. Pour accéder au bon compte il est important
										d'utiliser l'email <strong>{connectedAccountState.split("|")[1]}</strong> pour
										vous connecter.
									</p>
								) : connectedAccountState.startsWith("pending-valid") ? (
									<p>
										Votre compte stripe utilisant l'email{" "}
										<strong>{connectedAccountState.split("|")[1]}</strong> est en cours d'examen par
										nos équipes.
									</p>
								) : connectedAccountState.startsWith("no_account") ? (
									<p>
										Pour pouvoir utiliser PassPass pour des événements payants il faut tout d'abord
										créer un compte Stripe qui vous permettra de gérer l'ensemble des paiements. Ce
										compte sera lié à PassPass. Si vous souhaitez plus d'informations sur Stripe ...
									</p>
								) : (
									<p>
										Pour pouvoir utiliser PassPass pour des événements payants il faut tout d'abord
										créer un compte Stripe qui vous permettra de gérer l'ensemble des paiements. Ce
										compte sera lié à PassPass. Si vous souhaitez plus d'informations sur Stripe ...
									</p>
								)}
							</div>
							<div className="setting-block-content" style={{ marginTop: "20px" }}>
								{connectedAccountState.startsWith("valid") ? (
									<Button target="_blank" href="https://dashboard.stripe.com">
										Vers mon dashboard stripe
									</Button>
								) : connectedAccountState.startsWith("pending-valid") ? (
									<Button target="_blank" href="https://dashboard.stripe.com">
										Vers mon dashboard stripe
									</Button>
								) : connectedAccountState.startsWith("no_account") ? (
									<Button loading={loading} onClick={() => generate_stripe_link()}>
										Créer mon compte Stripe
									</Button>
								) : (
									<Button loading={loading} onClick={() => generate_stripe_link()}>
										Finaliser la création de mon compte Stripe
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default NewFinancialData;
