import React, { useEffect, useState } from "react";
import "./style.scss";
import { Modal } from "@mui/material";
import { Button, Input, Popconfirm, Select, Switch, Tooltip } from "antd";
import {
	AlignLeftOutlined,
	CaretDownOutlined,
	CheckCircleOutlined,
	CheckOutlined,
	CheckSquareOutlined,
	CloseOutlined,
	DeleteOutlined,
	FileTextOutlined,
	FontSizeOutlined,
	HolderOutlined,
	InfoCircleFilled,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../ticket_wrapper/TicketWrapper";
import { generateRandomString } from "helpers/main";

const ParticipationFormModal = ({ questions, selected, close, create, update, tickets }) => {
	const [question, setQuestion] = useState({
		title: "",
		type: "short-text",
		mandatory: false,
		allow_target: false,
		ticket_target: [],
		options: [],
		description: "",
	});

	useEffect(() => {
		if (selected.item != null) {
			if (selected.new) {
				setQuestion({
					title: "",
					type: "short-text",
					mandatory: false,
					allow_target: false,
					ticket_target: [],
					options: [],
					description: "",
				});
			} else {
				const question = questions.find((question) => question.id == selected.item);
				if (question) {
					setQuestion({
						title: question.title,
						type: question.type,
						mandatory: question.mandatory,
						id: question.id,
						allow_target: question.allow_target,
						ticket_target: question.ticket_target,
						options: question.options,
						description: question.description,
					});
				} else {
					setQuestion({
						title: "",
						type: "short-text",
						mandatory: false,
						allow_target: false,
						ticket_target: [],
						options: [],
						description: "",
					});
				}
			}
		}
	}, [selected]);

	const filterOptions = [
		...tickets.map((ticket) => {
			return {
				label: ticket.title,
				value: ticket.id,
			};
		}),
	];

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(question.options, result.source.index, result.destination.index);

		setQuestion({ ...question, options: items });
	};

	return (
		<Modal
			style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
			open={selected.item != null}
			onClose={close}
		>
			<div className="center-participation-form-modal">
				<div className="modal-header">
					<span>{selected.new ? "Créer un nouveau champ" : "Modifier le champ"}</span>
				</div>
				<div className="modal-content">
					<div className="mandatory-field">
						<span>
							Obligatoire{" "}
							<Tooltip title="Un champ obligatoire ne peut pas être laissé vide par l'utilisateur.">
								<InfoCircleFilled style={{ marginLeft: "10px" }} />
							</Tooltip>
						</span>
						<Switch
							value={question.mandatory}
							onChange={(e) => setQuestion({ ...question, mandatory: !question.mandatory })}
						/>
					</div>
					<div className="field-title-type">
						<div className="field-block large">
							<span>
								Etiquette{" "}
								<Tooltip title="Il s'agit du libellé de votre question.">
									<InfoCircleFilled style={{ marginLeft: "10px" }} />
								</Tooltip>
							</span>
							<Input
								className="input"
								value={question.title}
								onChange={(e) => setQuestion({ ...question, title: e.target.value })}
								placeholder="Entrez votre question"
							/>
						</div>
						<div className="field-block">
							<span>
								Type de champ{" "}
								<Tooltip title="Le type de champ vous permet de récupérer différents types d'informations.">
									<InfoCircleFilled style={{ marginLeft: "10px" }} />
								</Tooltip>
							</span>
							<Select
								className="input"
								value={question.type}
								onChange={(e) => setQuestion({ ...question, type: e })}
								options={[
									{
										value: "short-text",
										label: (
											<span>
												<FontSizeOutlined style={{ marginRight: "5px" }} /> Texte court
											</span>
										),
									},
									{
										value: "text",
										label: (
											<span>
												<AlignLeftOutlined style={{ marginRight: "5px" }} /> Paragraphe
											</span>
										),
									},
									{
										value: "unique-choice",
										label: (
											<span>
												<CheckCircleOutlined style={{ marginRight: "5px" }} /> Choix unique
											</span>
										),
									},
									{
										value: "multiple-choice",
										label: (
											<span>
												<CheckSquareOutlined style={{ marginRight: "5px" }} /> Choix multiple
											</span>
										),
									},
									{
										value: "selection",
										label: (
											<span>
												<CaretDownOutlined style={{ marginRight: "5px" }} /> Select from
												dropdown
											</span>
										),
									},
									{
										value: "additional-conditions",
										label: (
											<span>
												<FileTextOutlined style={{ marginRight: "5px" }} /> Condition
												supplémentaires
											</span>
										),
									},
									{
										value: "no-field",
										label: (
											<span>
												<AlignLeftOutlined style={{ marginRight: "5px" }} /> Texte sans champ
											</span>
										),
									},
								]}
							/>
						</div>
					</div>
					{["unique-choice", "multiple-choice", "selection"].includes(question.type) && (
						<div className="option-fields">
							<span>Champs d'options</span>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div
											className="options-wrapper"
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{question.options.map((option, id) => (
												<Draggable key={option.id} draggableId={option.id} index={id}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															className="option-field-item"
														>
															<div className="left">
																<div
																	className="order-container"
																	{...provided.dragHandleProps}
																>
																	<HolderOutlined style={{ fontSize: "18px" }} />
																</div>
																<Input
																	className="input"
																	placeholder="Titre de l'option"
																	value={option.title}
																	onChange={(e) => {
																		const options = question.options;
																		const new_item = {
																			title: e.target.value,
																			id: options[id].id,
																		};
																		options.splice(id, 1, new_item);
																		setQuestion({ ...question, options: options });
																	}}
																/>
															</div>
															<Popconfirm
																title="Supprimer l'option'"
																description="Etes vous sur de vouloir supprimer cette option?"
																onConfirm={() => {
																	const options = question.options;
																	options.splice(id, 1);
																	setQuestion({ ...question, options: options });
																}}
																okText="Yes"
																icon={
																	<QuestionCircleOutlined style={{ color: "red" }} />
																}
																cancelText="No"
															>
																<Button
																	danger
																	icon={
																		<DeleteOutlined style={{ color: "#f55956" }} />
																	}
																/>
															</Popconfirm>
														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							<Button
								disabled={
									question.options.length && question.options.filter((e) => e.title == "").length != 0
								}
								onClick={() =>
									setQuestion({
										...question,
										options: [...question.options, { title: "", id: generateRandomString(16) }],
									})
								}
								className="main"
								type="primary"
							>
								Nouvelle option
							</Button>
						</div>
					)}

					<div className="optional-settings">
						<span>Optional settings</span>
						<div className="activate">
							<span>N'afficher cette question que pour certains tickets</span>
							<Switch
								value={question.allow_target}
								onChange={(e) => setQuestion({ ...question, allow_target: !question.allow_target })}
							/>
						</div>
						{question.allow_target && (
							<Select
								mode="multiple"
								placeholder="Please select"
								value={question.ticket_target}
								onChange={(e) => setQuestion({ ...question, ticket_target: e })}
								options={filterOptions}
								className="select-item"
							/>
						)}
						<div className="field-block">
							<span>
								Description{" "}
								<Tooltip title="Il s'agit d'un texte descriptif court qui vous permet de préciser votre question.">
									<InfoCircleFilled style={{ marginLeft: "10px" }} />
								</Tooltip>
							</span>
							<Input
								className="input"
								value={question.description}
								onChange={(e) => setQuestion({ ...question, description: e.target.value })}
								placeholder="Entrez votre description"
							/>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<Button onClick={close}>Annuler</Button>
					<Button
						onClick={() => (selected.new ? create(question) : update(question))}
						disabled={question.title == ""}
						className="main"
						type="primary"
					>
						{selected.new ? "Ajouter le champ" : "Sauvegarder"}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ParticipationFormModal;
