// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-main-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px;
}

.dashboard-view-main-title-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-top: 20px;
}
.dashboard-view-main-title-header h1 {
  font-family: "Gabarito";
}

.table-value-status {
  min-width: 40px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
.table-value-status.bool {
  width: -moz-fit-content;
  width: fit-content;
}
.table-value-status.bool.true {
  background-color: var(--clr-success);
}
.table-value-status.bool.false {
  background-color: var(--clr-error);
}
.table-value-status.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-value-status.icon * {
  color: rgba(0, 0, 0, 0.5);
}
.table-value-status.icon i {
  display: block;
  margin-left: 5px;
}

.table-value-link {
  color: #faece3;
}

.event-content-padding {
  padding: 20px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/list_view.scss","webpack://./src/styles/dashboard.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;ACCD;;ADEA;EACC,aAAA;EACA,WAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;ACCD;ADCC;EACC,uBAAA;ACCF;;ADGA;EACC,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;ACAD;ADEC;EACC,uBAAA;EAAA,kBAAA;ACAF;ADCE;EACC,oCAAA;ACCH;ADEE;EACC,kCAAA;ACAH;ADIC;EAIC,aAAA;EACA,mBAAA;EACA,mBAAA;ACLF;ADAE;EACC,yBAAA;ACEH;ADIE;EACC,cAAA;EACA,gBAAA;ACFH;;ADOA;EACC,cAAA;ACJD;;AApDA;EACC,aAAA;EACA,WAAA;AAuDD","sourcesContent":[".dashboard-main-view {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n\tpadding: 20px 20px;\n}\n\n.dashboard-view-main-title-header {\n\tdisplay: flex;\n\twidth: 100%;\n\talign-items: center;\n\tjustify-content: space-between;\n\tpadding: 10px 0;\n\tmargin-top: 20px;\n\n\th1 {\n\t\tfont-family: \"Gabarito\";\n\t}\n}\n\n.table-value-status {\n\tmin-width: 40px;\n\twidth: fit-content;\n\tpadding: 2px 15px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tborder-radius: 6px;\n\tcolor: rgba($color: #000000, $alpha: 0.5);\n\tfont-weight: bold;\n\n\t&.bool {\n\t\twidth: fit-content;\n\t\t&.true {\n\t\t\tbackground-color: var(--clr-success);\n\t\t}\n\n\t\t&.false {\n\t\t\tbackground-color: var(--clr-error);\n\t\t}\n\t}\n\n\t&.icon {\n\t\t* {\n\t\t\tcolor: rgba($color: #000000, $alpha: 0.5);\n\t\t}\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\n\t\ti {\n\t\t\tdisplay: block;\n\t\t\tmargin-left: 5px;\n\t\t}\n\t}\n}\n\n.table-value-link {\n\tcolor: #faece3;\n}\n","@import \"./list_view.scss\";\n\n.event-content-padding {\n\tpadding: 20px;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
