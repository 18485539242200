import React from "react";
import { Link } from "react-router-dom";
import "styles/dashboard.scss";

const CellLink = ({ to, label, trunk = null }) => {
	return (
		<Link to={to} className="table-value-link">
			{trunk ? `${label.substring(0, trunk)}...` : label}
		</Link>
	);
};

export default CellLink;
