import React, { useState } from "react";
import DefaultView from "../../../components/settings/default_view/DefaultView";
import ToggleThemeButton from "../../../components/settings/toggle_theme_button/ToggleThemeButton";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const GeneralSettings = () => {
	const { t, i18n } = useTranslation("settings");

	const [language, setLanguage] = useState(i18n.language);

	const handleChange = (e) => {
		i18n.changeLanguage(e.target.value);
		setLanguage(e.target.value);
	};

	return (
		<DefaultView>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>{t("General settings")}</h1>
						<p>{t("Change how SmsDispatcher look")}</p>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("Interface theme")}</h2>
							<p>{t("Select your favourite UI theme")}</p>
						</div>
						<div className="setting-block-content">
							<ToggleThemeButton />
						</div>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("Interface language")}</h2>
							<p>{t("Select your favourite language.")}</p>
						</div>
						<div className="setting-block-content">
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
								<Select
									sx={{ width: "240px" }}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={language}
									label={t("Language")}
									onChange={handleChange}
								>
									<MenuItem value={"fr-FR"}>{t("French")} (Français)</MenuItem>
									<MenuItem value={"en"}>{t("English")} (English)</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default GeneralSettings;
