import { Col, Form, Input, Radio, Row } from "antd";
import DefaultView from "components/account_settings/default_view/DefaultView";
import LabelContent from "components/main/label_content/LabelContent";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { useForm } from "antd/es/form/Form";
import axios from "../../../services/axios";
import AuthContext from "contexts/AuthContext";

const FinancialData = () => {
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState(null);
	const [isValid, setIsValid] = useState(false);
	const [reload, setReload] = useState(false);
	const current_data = useRef(null);
	const [form] = useForm();

	const { user } = useContext(AuthContext);

	const reset = () => {
		form.resetFields();
		setIsValid(false);
	};

	const save = () => {
		form.validateFields()
			.then((e) => form.submit())
			.catch((e) => console.log("error"));
	};

	const submit = async () => {
		const financial_data = await axios
			.put(`api/organizers/${user.account.id}/finance`, form.getFieldValue())
			.then((e) => e.data)
			.catch((e) => null);

		if (financial_data) {
			setFormData({ ...financial_data });
			setIsValid(false);
		}
	};

	const load_financial_data = async () => {
		const financial_data = await axios
			.get(`api/organizers/${user.account.id}/finance`)
			.then((e) => e.data)
			.catch((e) => null);

		if (financial_data) {
			setFormData(financial_data);
			current_data.current = JSON.stringify(financial_data);
		}
	};

	useEffect(() => {
		load_financial_data();
	}, []);

	const account_type_is_individual = useMemo(
		() =>
			form.getFieldValue()?.account_holder_type == "individual" ||
			(!form.getFieldValue()?.account_holder_type && formData?.account_holder_type == "individual"),
		[reload, formData]
	);

	if (!formData) {
		return <DefaultView loading={loading} save={save} reset={reset} is_valid={() => false}></DefaultView>;
	}

	return (
		<DefaultView loading={loading} save={save} reset={reset} is_valid={() => isValid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>Finances</h1>
						<p>
							Ajoutez un compte bancaire pour recevoir les revenus de vos événements et gérer les finances
							de vos événements.
							<br />
							Ce compte sera votre compte bancaire par défaut pour tous vos événements.
						</p>
					</div>
					<Form
						onChange={() => {
							setIsValid(JSON.stringify(form.getFieldValue()) != JSON.stringify(formData));
						}}
						initialValues={formData}
						form={form}
						onFinish={submit}
						layout="vertical"
						className="setting-block"
					>
						<div id="financial-data" className="block-container full-width">
							<div className="setting-block-description">
								<h2>Informations sur le propriétaire du compte</h2>
							</div>
							<div className="setting-block-content">
								<Form.Item name="account_holder_type">
									<Radio.Group
										onChange={(e) => {
											setReload(!reload);
											form.setFieldsValue({
												...form.getFieldsValue(),
												account_holder_last_name: "",
											});
										}}
									>
										<Radio value={"individual"}>Personne physique</Radio>
										<Radio value={"company"}>Entreprise</Radio>
									</Radio.Group>
								</Form.Item>
								<Row className="input-row" gutter={20}>
									<Col>
										<Form.Item
											rules={[
												{
													required: true,
													message: account_type_is_individual
														? "Entrez un prénom"
														: "Entrez un nom d'entreprise",
												},
											]}
											label={account_type_is_individual ? "Prénom" : "Nom de l'entreprise"}
											name="account_holder_first_name"
										>
											<Input />
										</Form.Item>
									</Col>
									<Col>
										<Form.Item
											rules={[
												{
													required: account_type_is_individual,
													message: account_type_is_individual
														? "Entrez un prénom"
														: "Entrez un numéro de TVA",
												},
											]}
											label={account_type_is_individual ? "Nom" : "nº TVA"}
											name="account_holder_last_name"
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row className="input-row" gutter={20}>
									<Col>
										<Form.Item
											rules={[{ required: true, message: "Entrez une adresse" }]}
											label="Adresse, numéro"
											name="account_holder_street1"
										>
											<Input />
										</Form.Item>
									</Col>
									<Col>
										<Form.Item label="Complément d'adresse" name="account_holder_street2">
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row className="input-row" gutter={20}>
									<Col>
										<Form.Item
											rules={[{ required: true, message: "Entrez une ville" }]}
											label="Ville"
											name="account_holder_city"
										>
											<Input />
										</Form.Item>
									</Col>
									<Col>
										<Form.Item
											rules={[{ required: true, message: "Entrez un code postal" }]}
											label="Code postal"
											name="account_holder_postal_code"
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
							</div>
							<div className="setting-block-description">
								<h2>Informations sur le compte en banque</h2>
							</div>
							<div className="setting-block-content">
								<Form.Item name="account_bank_type">
									<Radio.Group>
										<Radio value={"checking"}>Compte courant</Radio>
										<Radio value={"savings"}>Compte épargne</Radio>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Entrez le nom de la banque",
										},
									]}
									label={"Nom de la banque"}
									name="account_bank_name"
								>
									<Input />
								</Form.Item>
								<Row className="input-row" gutter={20}>
									<Col>
										<Form.Item
											rules={[{ required: true, message: "Entrez un IBAN" }]}
											label="IBAN"
											name="account_bank_iban"
										>
											<Input />
										</Form.Item>
									</Col>
									<Col>
										<Form.Item
											rules={[{ required: true, message: "Entrez un BIC" }]}
											label="Code BIC"
											name="account_bank_bic"
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Entrez un nom pour le compte",
										},
									]}
									label={"Nom du compte"}
									name="account_bank_nickname"
								>
									<Input />
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</DefaultView>
	);
};

export default FinancialData;
