// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-code-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  padding: 20px;
  border-radius: var(--border-radius);
  gap: 40px;
}
.promo-code-container .promo-code-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.promo-code-container .promo-code-wrapper .promo-code-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid transparent;
  transition: 0.3s;
  margin-bottom: 15px;
  width: 100%;
}
.promo-code-container .promo-code-wrapper .promo-code-item .column {
  display: flex;
  flex-direction: column;
}
.promo-code-container .promo-code-wrapper .promo-code-item .column .linked-count {
  display: inline-flex;
  flex-direction: row;
  margin-right: 10px;
  gap: 5px;
  color: #4589ff;
}
.promo-code-container .promo-code-wrapper .promo-code-item:not(.disabled) {
  cursor: pointer;
}
.promo-code-container .promo-code-wrapper .promo-code-item:not(.disabled):hover {
  border-color: var(--divider);
}
.promo-code-container .promo-code-wrapper .promo-code-item .code span {
  font-weight: bold;
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/events/create_update_event/tickets/promo_code/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,sBAAA;EACA,aAAA;EACA,mCAAA;EACA,SAAA;AACD;AACC;EACC,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AACH;AACG;EACC,aAAA;EACA,sBAAA;AACJ;AACI;EACC,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,cAAA;AACL;AAGG;EACC,eAAA;AADJ;AAIG;EACC,4BAAA;AAFJ;AAMI;EACC,iBAAA;EACA,iBAAA;AAJL","sourcesContent":[".promo-code-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: flex-start;\n\tjustify-content: flex-start;\n\tbackground-color: #fff;\n\tpadding: 20px;\n\tborder-radius: var(--border-radius);\n\tgap: 40px;\n\n\t.promo-code-wrapper {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\twidth: 100%;\n\n\t\t.promo-code-item {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\talign-items: center;\n\t\t\tjustify-content: space-between;\n\t\t\tpadding: 20px;\n\t\t\tborder-radius: 15px;\n\t\t\tbackground-color: rgba($color: #000000, $alpha: 0.02);\n\t\t\tborder: 1px solid transparent;\n\t\t\ttransition: 0.3s;\n\t\t\tmargin-bottom: 15px;\n\t\t\twidth: 100%;\n\n\t\t\t.column {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\n\t\t\t\t.linked-count {\n\t\t\t\t\tdisplay: inline-flex;\n\t\t\t\t\tflex-direction: row;\n\t\t\t\t\tmargin-right: 10px;\n\t\t\t\t\tgap: 5px;\n\t\t\t\t\tcolor: #4589ff;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&:not(.disabled) {\n\t\t\t\tcursor: pointer;\n\t\t\t}\n\n\t\t\t&:not(.disabled):hover {\n\t\t\t\tborder-color: var(--divider);\n\t\t\t}\n\n\t\t\t.code {\n\t\t\t\tspan {\n\t\t\t\t\tfont-weight: bold;\n\t\t\t\t\tfont-size: 1.2rem;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
