import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchEvents = createAsyncThunk("events/fetchEvents", async (current_organizer) => {
	if (!current_organizer) return null;
	const response = await axios.get(`api/dashboard/${current_organizer}/events`);
	return response.data;
});

export const updateEventStatus = createAsyncThunk(
	"events/updateEventStatus",
	async ({ event_id, status }) => {
		const response = await axios.put(`api/events/${event_id}/status/${status}`);
		return response.data;
	}
);

const eventsSlice = createSlice({
	name: "events",
	initialState: [],
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchEvents.fulfilled, (state, { payload }) => {
			if (!payload) return [];
			payload.forEach((event) => {
				event.start_at_time = new Date(event.start_at).getTime();
			});
			return payload.sort((a, b) => b.id - a.id);
		});
		builder.addCase(updateEventStatus.fulfilled, (state, { payload }) => {
			state.map((event) => {
				if (event.id == payload.id) {
					event.status = payload.status;
				}
			});
		});
	},
});

export const eventsMethods = {
	fetchEvents,
	updateEventStatus,
};

export default eventsSlice;
