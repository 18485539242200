import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import DefaultView from "../../../components/settings/default_view/DefaultView";
import AuthContext from "../../../contexts/AuthContext";

const Profile = () => {
	const { user } = useContext(AuthContext);
	const [settingsData, setSettingsData] = useState({
		first_name: user.first_name,
		last_name: user.last_name,
	});

	const save = async () => {};

	const reset = () => {};

	const valid = () => {
		return (
			settingsData.first_name != user.first_name ||
			settingsData.last_name != user.last_name
		);
	};

	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>Profile</h1>
						<p>Change your profile details</p>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>Basic data</h2>
							<p>Change your name</p>
						</div>
						<div className="setting-block-content">
							<form className="setting-block-form">
								<TextField
									size="small"
									label="First name"
									value={settingsData.first_name}
									onChange={(e) =>
										setSettingsData({
											...settingsData,
											first_name: e.target.value,
										})
									}
								/>
								<TextField
									size="small"
									label="Last name"
									value={settingsData.last_name}
									onChange={(e) =>
										setSettingsData({
											...settingsData,
											last_name: e.target.value,
										})
									}
								/>
							</form>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default Profile;
