import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TicketItem from "../TicketItem";
import TIcketUpdateModal from "../TIcketUpdateModal";
import { Button } from "@mui/material";

export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const TicketWrapper = ({ all_tickets, event, formData, check_if_can_save, is_addon = false }) => {
	const [tickets, setTickets] = useState(all_tickets);
	const [updateTicket, setUpdateTicket] = useState({
		item: null,
	});

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(tickets, result.source.index, result.destination.index);
		items.forEach((item) => (item.need_update = true));
		formData.current.tickets = items;

		setTickets(items);
		check_if_can_save();
	};

	const new_ticket = () => {
		const current_size = tickets.length;
		const new_ticket = {
			id: `new_${(Math.random() + 1).toString(36).substring(7)}`,
			title: "",
			description: "",
			price: 0,
			amount: 0,
			sell_start_at: null,
			sell_end_at: null,
			min_per_order: 0,
			max_per_order: 0,
			visibility: "",
			restricted: "",
			sort: 1,
			ticket_type: is_addon ? "addon" : "ticket",
		};
		setTickets([...tickets, new_ticket]);

		const base_tickets = is_addon ? formData.current.addons : formData.current.tickets;
		base_tickets.push(new_ticket);

		setUpdateTicket({
			new: true,
			item: current_size,
		});
	};

	return (
		<>
			<TIcketUpdateModal
				event={event}
				open={updateTicket.item != null}
				handleClose={() => {
					const base_tickets = is_addon ? formData.current.addons : formData.current.tickets;
					if (base_tickets[updateTicket.item] && !base_tickets[updateTicket.item]?.title) {
						base_tickets.splice(updateTicket.item);
						setTickets([...base_tickets]);
					}
					setUpdateTicket({ item: null });
					check_if_can_save();
				}}
				ticket={tickets[updateTicket.item]}
				is_new={updateTicket.new}
				saveTicket={(ticket) => {
					const base_tickets = is_addon ? formData.current.addons : formData.current.tickets;
					base_tickets[updateTicket.item] = { ...ticket, need_update: true };
					setTickets([...base_tickets]);
					setUpdateTicket({ item: null });
					check_if_can_save();
				}}
			/>
			<div className="tickets-addon-container">
				<div onClick={new_ticket} className="global-fill-btn">
					{!is_addon ? "Nouvelle entrée" : "Nouveau supplément"}
				</div>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div className="ticket-addon-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
								{tickets.map((ticket, id) => (
									<TicketItem
										key={id}
										ticket={ticket}
										id={id}
										update={() => setUpdateTicket({ item: id, new: null })}
									/>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</>
	);
};

export default TicketWrapper;
