import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import EventCreationContext from "../../../contexts/EventCreationContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CreateUpdateAside from "components/events/create_update_event/create_update_aside/CreateUpdateAside";
import GeneralDataPreview from "components/events/create_update_event/previews/GeneralDataPreview";
import { ColorPicker, Input } from "antd";

const Customization = () => {
	const { event, update_event, step, change_step, base_data } = useContext(EventCreationContext);
	const formData = useRef({
		primary_color: event.primary_color ? event.primary_color : "#000000",
		confirmation_page_message: event.confirmation_page_message,
		email_pdf_message: event.email_pdf_message,
		email_pdf_email_response: event.email_pdf_email_response,
	});
	const [formDataState, setFormDataState] = useState({ ...formData.current });

	const save_data = () => {
		update_event("customization", formData.current);
	};

	useEffect(() => {
		return () => {
			save_data();
		};
	}, []);

	useEffect(() => {
		formData.current = { ...formDataState };
	}, [formDataState]);

	return (
		<>
			<div className="create-update-center">
				<div onClick={() => change_step(step.step - 1)} className="go-back-container">
					<ArrowBackIosIcon />
					<span>Informations générales</span>
				</div>
				<h1>Personnalisation</h1>
				<div id="config" className="input-container">
					<h2>Couleurs</h2>
					<p>Choisissez la couleur principale de votre événement</p>
					<label htmlFor="event-primary">Couleur primaire</label>

					<ColorPicker
						id="event-primary"
						className="color-input"
						defaultValue={formData.current.primary_color}
						allowClear={false}
						disabledAlpha={true}
						format="hex"
						size="large"
						showText
						onChangeComplete={(e) => setFormDataState({ ...formDataState, primary_color: e.toHexString() })}
					/>
				</div>
				<div className="input-container">
					<h2>Personnaliser la page web</h2>
					<p>
						Une fois inscrits à votre événement, les participants verront un message de validation
						s'afficher à l'écran. Personnalisez le message, ajoutez de instructions spéciales ou toute autre
						information utile.
					</p>
					<div className="label-max-length">
						<label htmlFor="web-message">Message personnalisé</label>
						<span>{formDataState.confirmation_page_message.length} sur 170</span>
					</div>
					<Input.TextArea
						id="web-message"
						maxLength={170}
						rows={4}
						className="classic-text-area"
						value={formDataState.confirmation_page_message}
						onChange={(e) =>
							setFormDataState({ ...formDataState, confirmation_page_message: e.target.value })
						}
					/>
				</div>
				<div className="input-container">
					<h2>Personnaliser l'email</h2>
					<p>
						Une fois inscrits à votre événement, les participants recevront un email de confirmation.
						Personnalisez le message, ajoutez des instructions spéciales ou toute autre information utile.
					</p>

					<label htmlFor="web-message">Message personnalisé</label>
					<Input.TextArea
						id="web-message"
						rows={4}
						className="classic-text-area"
						value={formDataState.email_pdf_message}
						onChange={(e) => setFormDataState({ ...formDataState, email_pdf_message: e.target.value })}
					/>
					<label htmlFor="web-message">Email de réponse</label>
					<p>
						Choisissez votre adresse e-mail de « réponse » (il s'agit de l'adresse e-mail à laquelle vous
						souhaitez recevoir les réponses aux messages)
					</p>
					<Input
						id="web-message"
						className="classic-text-input"
						value={formDataState.email_pdf_email_response}
						onChange={(e) =>
							setFormDataState({ ...formDataState, email_pdf_email_response: e.target.value })
						}
					/>
				</div>
			</div>
			<CreateUpdateAside
				tips={[
					{
						title: "Couleur assortie",
						content: `Pour assortir une couleur à l'image de couverture de votre événement, utilisez <a href="https://imagecolorpicker.com" target="_blank">ce site</a>.`,
					},
					{
						title: "Couleur du texte automatique",
						content: `Ne vous inquiétez pas pour la couleur du texte sur vos boutons. Elle sera choisie automatiquement en fonction de la couleur que vous aurez sélectionné pour faciliter la lecture.`,
					},
				]}
				can_save={
					JSON.stringify({
						primary_color: base_data.current.primary_color ? base_data.current.primary_color : "#000000",
						confirmation_page_message: base_data.current.confirmation_page_message,
						email_pdf_message: base_data.current.email_pdf_message,
						email_pdf_email_response: base_data.current.email_pdf_email_response,
					}) != JSON.stringify(formDataState)
				}
				save={save_data}
			>
				<GeneralDataPreview
					main_picture={null}
					event={event}
					data={{
						...event,
						...formDataState,
						description: "",
						title: "",
						small_description: "",
					}}
					show_button={true}
				/>
			</CreateUpdateAside>
		</>
	);
};

export default Customization;
