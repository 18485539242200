import Lottie from "lottie-react";
import React from "react";
import loading from "assets/lottie/empty.json";
import "./style.scss";

const PageLoading = ({ height = "100vh" }) => {
	return (
		<div className="page-loading-full" style={{ height: height }}>
			<Lottie className="center-animation" animationData={loading} />
		</div>
	);
};

export default PageLoading;
