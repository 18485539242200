import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import EventCreationContext from "contexts/EventCreationContext";
import GeneralDataPreview from "../previews/GeneralDataPreview";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { Button, Select } from "antd";

const SummaryAside = () => {
	const { event, continue_and_save, update_event, loading, publish, base_data } = useContext(EventCreationContext);
	const [innerLoading, setInnerLoading] = useState(false);
	const [visibility, setVisibility] = useState(event.visibility);
	const [status, setStatus] = useState(event.status);
	const navigate = useNavigate();

	const save_logic = async (need_redirect = true) => {
		setInnerLoading(true);
		const id = await continue_and_save(need_redirect);
		setInnerLoading(false);
		return id;
	};

	const save_data = () => {
		update_event("summary", {
			visibility: visibility,
			status: event.status,
		});
	};

	const open_website = async () => {
		if (innerLoading) return;
		const id = event?.id ? event.id : await save_logic(false);
		window.open(
			`${process.env.REACT_APP_SITE_URL}/events/${id}/${
				event.title ? encodeURIComponent(event.title.replaceAll(" ", "-")) : "passpass"
			}/preview`
		);
	};

	useEffect(() => {
		if (visibility) {
			save_data();
		}
	}, [visibility]);

	return (
		<div id="summary-aside">
			<div onClick={open_website} className="aside-btn">
				<RemoveRedEyeIcon />
				<span>Prévisualiser l'événement</span>
			</div>
			<div className="preview-container-create-event">
				<GeneralDataPreview show_button={true} main_picture={null} event={event} data={event} />
			</div>
			<div className="event-visibility">
				<span className="title">Qui peut voir l'événement ?</span>
				<div className="radio-group">
					<input
						checked={visibility == "public"}
						onChange={() => setVisibility("public")}
						type="radio"
						id="visibility_radio_1"
						name="visibility_radio"
					/>
					<label className="form-check-label" htmlFor="visibility_radio_1">
						<span className="label-title">Public</span>
						<span>Visible sur PassPass et les moteurs de recherche</span>
					</label>
				</div>
				<div className="radio-group">
					<input
						checked={visibility == "private"}
						onChange={() => setVisibility("private")}
						type="radio"
						id="visibility_radio_2"
						name="visibility_radio"
					/>
					<label className="form-check-label" htmlFor="visibility_radio_2">
						<span className="label-title">Privé</span>
						<span>Accessible via le lien de l'événement</span>
					</label>
				</div>
			</div>
			{event.status && event.status != "draft" && (
				<Select
					style={{ width: "100%" }}
					size="small"
					fullWidth
					value={status}
					onChange={(e) => setStatus(e)}
					options={[
						{ value: "published", label: "Publié" },
						{ value: "standby", label: "En veille" },
						{ value: "archived", label: "Archivé" },
					]}
				/>
			)}
			<Button
				style={{ width: "100%" }}
				type="primary"
				loading={loading}
				disabled={
					(event.visibility == "" || JSON.stringify(base_data.current) == JSON.stringify(event)) &&
					!(event.status && event.status != "draft" && status != event.status)
				}
				onClick={async () => {
					const id = await publish(false, event.status && event.status != "draft" ? status : "published");
					navigate(`/events/${id}`);
				}}
			>
				<span>Mettre à jour le status</span>
			</Button>
		</div>
	);
};

export default SummaryAside;
