// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#account-members .intro-content h1 {
  font-family: "Gabarito";
}
#account-members .intro-content p {
  margin-top: 20px;
  max-width: 500px;
}
#account-members .create-event-btn {
  background-color: #191919;
  border-radius: 10px;
  padding: 10px;
  width: 200px;
}
#account-members .create-event-btn span {
  color: white;
  font-family: "Gabarito";
  transition: 0.3s ease;
  margin-left: -15px;
  font-size: 12px;
}
#account-members .create-event-btn img {
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: 0.3s ease;
  transform: rotate(90deg);
  margin-right: -15px;
}
#account-members .create-event-btn:hover span {
  margin-left: 5px;
}
#account-members .create-event-btn:hover img {
  opacity: 1;
  transform: rotate(0deg);
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/screens/events/event_members/style.scss"],"names":[],"mappings":"AAEE;EACC,uBAAA;AADH;AAGE;EACC,gBAAA;EACA,gBAAA;AADH;AAKC;EACC,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAHF;AAKE;EACC,YAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AAHH;AAME;EACC,WAAA;EACA,YAAA;EACA,UAAA;EACA,qBAAA;EACA,wBAAA;EACA,mBAAA;AAJH;AAQG;EACC,gBAAA;AANJ;AAQG;EACC,UAAA;EACA,uBAAA;EACA,eAAA;AANJ","sourcesContent":["#account-members {\n\t.intro-content {\n\t\th1 {\n\t\t\tfont-family: \"Gabarito\";\n\t\t}\n\t\tp {\n\t\t\tmargin-top: 20px;\n\t\t\tmax-width: 500px;\n\t\t}\n\t}\n\n\t.create-event-btn {\n\t\tbackground-color: #191919;\n\t\tborder-radius: 10px;\n\t\tpadding: 10px;\n\t\twidth: 200px;\n\n\t\tspan {\n\t\t\tcolor: white;\n\t\t\tfont-family: \"Gabarito\";\n\t\t\ttransition: 0.3s ease;\n\t\t\tmargin-left: -15px;\n\t\t\tfont-size: 12px;\n\t\t}\n\n\t\timg {\n\t\t\twidth: 20px;\n\t\t\theight: 20px;\n\t\t\topacity: 0;\n\t\t\ttransition: 0.3s ease;\n\t\t\ttransform: rotate(90deg);\n\t\t\tmargin-right: -15px;\n\t\t}\n\n\t\t&:hover {\n\t\t\tspan {\n\t\t\t\tmargin-left: 5px;\n\t\t\t}\n\t\t\timg {\n\t\t\t\topacity: 1;\n\t\t\t\ttransform: rotate(0deg);\n\t\t\t\tmargin-right: 0;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
