// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#demo {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#demo .demo-center {
  max-width: 1500px;
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#demo h1 {
  color: #322f2d;
  font-size: 48px;
  font-weight: 600;
}
#demo p {
  max-width: 500px;
  color: #191919;
  font-size: 16px;
  font-weight: 300;
}
#demo .links-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 25px;
  flex-wrap: wrap;
}
#demo .links-container a {
  display: block;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #000;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  color: #000;
  padding: 10px 20px;
  transition: 0.3s;
}
#demo .links-container a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
#demo .links-container a.home {
  color: #fff;
  background-color: #000;
}
#demo .links-container a.home:hover {
  background-color: rgba(0, 0, 0, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/screens/auth/demo/style.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,iBAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;AAEC;EACC,cAAA;EACA,eAAA;EACA,gBAAA;AAAF;AAGC;EACC,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AADF;AAIC;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;AAFF;AAIE;EACC,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gDAAA;EACA,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AAFH;AAIG;EACC,qCAAA;AAFJ;AAKG;EACC,WAAA;EACA,sBAAA;AAHJ;AAKI;EACC,oCAAA;AAHL","sourcesContent":["#demo {\n\tmin-height: calc(100svh);\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\n\t.demo-center {\n\t\tmax-width: 1500px;\n\t\twidth: 100%;\n\t\tpadding: 5%;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: flex-start;\n\t}\n\n\th1 {\n\t\tcolor: #322f2d;\n\t\tfont-size: 48px;\n\t\tfont-weight: 600;\n\t}\n\n\tp {\n\t\tmax-width: 500px;\n\t\tcolor: #191919;\n\t\tfont-size: 16px;\n\t\tfont-weight: 300;\n\t}\n\n\t.links-container {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: space-between;\n\t\tgap: 10px;\n\t\tmargin-top: 25px;\n\t\tflex-wrap: wrap;\n\n\t\ta {\n\t\t\tdisplay: block;\n\t\t\tborder-radius: 10px;\n\t\t\tbackground: #fff;\n\t\t\tborder: 1px solid #000;\n\t\t\tbox-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);\n\t\t\ttext-decoration: none;\n\t\t\tcolor: #000;\n\t\t\tpadding: 10px 20px;\n\t\t\ttransition: 0.3s;\n\n\t\t\t&:hover {\n\t\t\t\tbackground-color: rgba($color: #000000, $alpha: 0.05);\n\t\t\t}\n\n\t\t\t&.home {\n\t\t\t\tcolor: #fff;\n\t\t\t\tbackground-color: #000;\n\n\t\t\t\t&:hover {\n\t\t\t\t\tbackground-color: rgba($color: #000000, $alpha: 0.7);\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
