import React from "react";
import "styles/dashboard.scss";

const CellBoolean = ({
	value,
	colors = ["var(--clr-success)", "var(--clr-error)"],
	labels = ["IN", "OUT"],
}) => {
	return <span className={`table-value-status bool ${value}`}>{labels[value ? 0 : 1]}</span>;
};

export default CellBoolean;
