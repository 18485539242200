import React from "react";
import "./style.scss";

const NavigationItem = ({ title, status, step, onClick }) => {
	return (
		<span onClick={onClick} className={`navigation-item ${status === null ? "" : status ? "ok" : "err"}`}>
			{title}
		</span>
	);
};

const CreateUpdateNavigation = ({ step, validation, change_step }) => {
	return (
		<div id="create-update-navigation">
			<div className={`navigation-block ${step.step === 0 ? "current" : ""}`}>
				<span onClick={() => change_step(0)} className="navigation-title">
					Informations générales
				</span>
				<NavigationItem
					title={"Titre"}
					status={validation.general_informations}
					onClick={() => change_step(0, "title")}
				/>
				<NavigationItem
					title={"Détails"}
					status={validation.details}
					onClick={() => change_step(0, "details")}
				/>
				<NavigationItem title={"Medias"} status={validation.medias} onClick={() => change_step(0, "medias")} />
				<NavigationItem
					title={"Date et heure"}
					status={validation.date}
					onClick={() => change_step(0, "date-hour")}
				/>
				<NavigationItem
					title={"Localisation"}
					status={validation.location}
					onClick={() => change_step(0, "location")}
				/>
			</div>
			<div className={`navigation-block ${step.step === 1 ? "current" : ""}`}>
				<span onClick={() => change_step(1)} className="navigation-title">
					Personnalisation
				</span>
				<NavigationItem
					onClick={() => change_step(1, "config")}
					title={"Configuration"}
					status={validation.customization}
				/>
			</div>
			<div className={`navigation-block ${step.step === 2 ? "current" : ""}`}>
				<span onClick={() => change_step(2)} className="navigation-title">
					Tickets
				</span>
				<NavigationItem
					onClick={() => change_step(2, "config")}
					title={"Configuration"}
					status={validation.tickets}
				/>
			</div>
			<div className={`navigation-block ${step.step === 4 ? "current" : ""}`}>
				<span onClick={() => change_step(4)} className="navigation-title">
					Publication
				</span>
				<NavigationItem
					onClick={() => change_step(4, "recap")}
					title={"Recapitulatif"}
					status={validation.summary}
				/>
			</div>
		</div>
	);
};

export default CreateUpdateNavigation;
