import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import "styles/dashboard.scss";
import { Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { generate_image_path } from "helpers/images";
import { format_date, format_price, format_time_range } from "helpers/format";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArchiveIcon from "@mui/icons-material/Archive";
import AuthContext from "contexts/AuthContext";
import { ContentCopy, EditNote } from "@mui/icons-material";
import addIcon from "../../../../src/assets/static/add-icon.svg";
import ConfirmationContext from "contexts/ConfirmationContext";
import { Select, Tag } from "antd";

const event_status_color = {
	archived: "",
	draft: "",
	published: "green",
	standby: "blue",
	deleted: "red",
};

const EventList = ({ events, fetchEvents, updateEventStatus }) => {
	const navigate = useNavigate();
	const [columnAnchor, setColumnAnchor] = useState(null);
	const { user } = useContext(AuthContext);
	const current_event = useRef(null);
	const location = useLocation();
	const [status, setStatus] = useState("next");
	const [selectedEvents, setSelectedEvents] = useState(events);
	const { open_confirm } = useContext(ConfirmationContext);

	useEffect(() => {
		fetchEvents(user.account.id);
	}, [location]);

	useEffect(() => {
		if (events && events.length) {
			if (status == "all") {
				setSelectedEvents(events.filter((e) => e.status != "archived"));
			} else if (status == "standby" || status == "archived") {
				setSelectedEvents(events.filter((e) => e.status == status));
			} else if (status == "past") {
				const now = new Date();
				setSelectedEvents(events.filter((e) => new Date(e.end_at) < now && e.status != "archived"));
			} else if (status == "next") {
				const now = new Date();
				setSelectedEvents(events.filter((e) => new Date(e.end_at) > now && e.status != "archived"));
			}
		}
	}, [status, events]);

	return (
		<div id="dashboard-event-list" className="dashboard-main-view">
			<div className="dashboard-view-main-title-header">
				<h1>Mes événements</h1>

				<Button onClick={() => navigate("/create-event")} className="create-event-btn">
					<img src={addIcon} alt="" />
					<span>Créer un événement</span>
				</Button>
			</div>
			<Menu
				id="basic-event-menu"
				anchorEl={columnAnchor}
				open={columnAnchor != null}
				onClose={() => setColumnAnchor(null)}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<div className="card-menu">
					<MenuItem
						onClick={() => {
							setColumnAnchor(null);
							window.open(
								`${process.env.REACT_APP_SITE_URL}/events/${
									current_event.current.id
								}/${encodeURIComponent(current_event.current.title.replaceAll(" ", "-"))}`
							);
						}}
					>
						<ListItemIcon>
							<ContentCopy fontSize="small" />
						</ListItemIcon>
						<ListItemText>Afficher l'événement</ListItemText>
					</MenuItem>
					<Divider />
					<MenuItem
						onClick={() => {
							setColumnAnchor(null);
							navigate("/update-event/" + current_event.current?.id);
						}}
					>
						<ListItemIcon>
							<EditNote fontSize="small" />
						</ListItemIcon>
						<ListItemText>Modifier</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setColumnAnchor(null);
							updateEventStatus({
								event_id: current_event.current?.id,
								status: current_event.current?.status == "archived" ? "published" : "archived",
							});
						}}
						className="warning item"
					>
						<ListItemIcon>
							<ArchiveIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							{current_event.current?.status == "archived" ? "Désarchiver" : "Archiver"}
						</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setColumnAnchor(null);
							open_confirm(
								`Delete ${current_event.current?.title}`,
								"By deleting this evenement you will not be able to access it anymore",
								"Delete",
								() =>
									updateEventStatus({
										event_id: current_event.current?.id,
										status: "deleted",
									})
							);
						}}
						className="error item"
					>
						<ListItemIcon>
							<DeleteForeverIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Supprimer</ListItemText>
					</MenuItem>
				</div>
			</Menu>
			<table className="event-list-table">
				<thead>
					<tr>
						<th>
							<div className="event-status-toggle">
								<span>Événement</span>
								<Select
									size="small"
									value={status}
									style={{ minWidth: "200px" }}
									onChange={(e) => setStatus(e)}
									options={[
										{ value: "all", label: "Tous les événements" },
										{ value: "next", label: "Événements à venir" },
										{ value: "past", label: "Événements passés" },
										{ value: "standby", label: "Événements en veille" },
										{ value: "archived", label: "Archivé" },
									]}
								/>
							</div>
						</th>
						<th>Ventes</th>
						{/* <th>Revenus</th> */}
						<th>Statut</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{selectedEvents.map((event, id) => (
						<tr key={id}>
							<td onClick={() => navigate(`/events/${event.id}`)}>
								<div className="event-main-card">
									<img src={generate_image_path(event.id, event.main_picture, "phone")} />{" "}
									<div className="card-content">
										<h2>{event.title}</h2>
										<p>
											{format_date(event.start_at)} |{" "}
											{format_time_range(event.start_at, event.end_at)}
										</p>
									</div>
								</div>
							</td>
							<td onClick={() => navigate(`/events/${event.id}`)}>
								<div className="sold">
									<div className="sold-container">
										{/* <span>Tickets: </span> */}
										<div className="sold-content">
											<span
												className="sold-amount"
												style={{
													width: `${
														(event.tickets_count == 0
															? 0
															: event.tickets_sold / event.tickets_count) * 100
													}%`,
												}}
											></span>
											<span className="content">
												{event.tickets_sold} <span>vendus</span>
											</span>
										</div>
									</div>
									{/* <div className="sold-container">
										<span>Suppléments: </span>
										<div className="sold-content">
											<span
												className="sold-amount"
												style={{
													width: `${
														(event.addons_count == 0
															? 0
															: event.addons_sold / event.addons_count) * 100
													}%`,
												}}
											></span>
											<span className="content">
												{event.addons_sold} <span>/ {event.addons_count}</span>
											</span>
										</div>
									</div> */}
								</div>
							</td>
							{/* <td onClick={() => navigate(`/events/${event.id}`)}>{format_price(event.gross)}</td> */}
							<td onClick={() => navigate(`/events/${event.id}`)}>
								<Tag color={event_status_color[event.status == "" ? "draft" : event.status]}>
									{event.status == "" ? "draft" : event.status}
								</Tag>
							</td>
							<td
								onClick={(e) => {
									setColumnAnchor(e.target);
									current_event.current = event;
								}}
							>
								<IconButton>
									<MoreVertIcon />
								</IconButton>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
