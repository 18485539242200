// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-number-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background-color: #fff;
  gap: 40px;
}
.big-number-view .day-logic {
  position: absolute;
  top: 40px;
}
.big-number-view img {
  width: 80px;
  height: auto;
  position: absolute;
  bottom: 40px;
}
.big-number-view .big-number-container {
  display: flex;
  transition: 0.5s;
}
.big-number-view .big-number-container.green {
  color: #9ac058;
}
.big-number-view .big-number-container.red {
  color: #c63636;
}
.big-number-view .big-number-container .digit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.big-number-view .big-number-container .digit-container.short {
  width: 20px;
}
.big-number-view .big-number-container .digit-container span {
  font-weight: bold;
  font-size: 54px;
}`, "",{"version":3,"sources":["webpack://./src/screens/admin/big_number/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,eAAA;EACA,sBAAA;EACA,SAAA;AACD;AACC;EACC,kBAAA;EACA,SAAA;AACF;AAEC;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAGC;EACC,aAAA;EACA,gBAAA;AADF;AAGE;EACC,cAAA;AADH;AAIE;EACC,cAAA;AAFH;AAKE;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AAHH;AAKG;EACC,WAAA;AAHJ;AAMG;EACC,iBAAA;EACA,eAAA;AAJJ","sourcesContent":[".big-number-view {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding-bottom: 40px;\n\tposition: fixed;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n\tz-index: 100000;\n\tbackground-color: #fff;\n\tgap: 40px;\n\n\t.day-logic {\n\t\tposition: absolute;\n\t\ttop: 40px;\n\t}\n\n\timg {\n\t\twidth: 80px;\n\t\theight: auto;\n\t\tposition: absolute;\n\t\tbottom: 40px;\n\t}\n\n\t.big-number-container {\n\t\tdisplay: flex;\n\t\ttransition: 0.5s;\n\n\t\t&.green {\n\t\t\tcolor: #9ac058;\n\t\t}\n\n\t\t&.red {\n\t\t\tcolor: #c63636;\n\t\t}\n\n\t\t.digit-container {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\twidth: 48px;\n\t\t\theight: 48px;\n\n\t\t\t&.short {\n\t\t\t\twidth: 20px;\n\t\t\t}\n\n\t\t\tspan {\n\t\t\t\tfont-weight: bold;\n\t\t\t\tfont-size: 54px;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
