import React, { useContext, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import EventCreationContext from "contexts/EventCreationContext";
import { LoadingButton } from "@mui/lab";
import ConfirmationContext from "contexts/ConfirmationContext";

const CreateUpdateAside = ({ save, can_save, tips = [], children = null, clean = () => null }) => {
	const { continue_and_save, event, change_step, step } = useContext(EventCreationContext);
	const { setToast } = useContext(ConfirmationContext);
	const [loading, setLoading] = useState(false);
	const [currentTip, setCurrentTip] = useState(0);

	const save_logic = async () => {
		setLoading(true);
		save();
		const id = await continue_and_save(false);
		setLoading(false);
		clean();
		return id;
	};

	const open_website = async () => {
		if (loading) return;
		const id = event?.id && !can_save ? event.id : await save_logic(false);
		window.open(
			`${process.env.REACT_APP_SITE_URL}/events/${id}/${
				event.title ? encodeURIComponent(event.title.replaceAll(" ", "-")) : "passpass"
			}/preview`
		);
	};

	return (
		<div id="create-update-aside">
			<div className="top-view">
				{tips.length != 0 && (
					<div className="quick-tips-container">
						<div className="quick-tip">
							<div className="tip-swapper">
								<i
									onClick={() => setCurrentTip(currentTip - 1)}
									className={`fas fa-chevron-left ${currentTip == 0 ? "disabled" : ""}`}
								></i>
								<div className="circle-container">
									{tips.map((elem, id) => (
										<span
											className={`tip-circle ${id == currentTip ? "current" : ""}`}
											key={id}
										></span>
									))}
								</div>
								<i
									onClick={() => setCurrentTip(currentTip + 1)}
									className={`fas fa-chevron-right ${
										currentTip == tips.length - 1 ? "disabled" : ""
									}`}
								></i>
							</div>
							<span className="tip-top-title">Quick tip !</span>
							<span className="tip-title">{tips[currentTip].title}</span>
							<span
								className="tip-content"
								dangerouslySetInnerHTML={{ __html: tips[currentTip].content }}
							/>
						</div>
					</div>
				)}
				{children != null && (
					<div className="preview-title">
						<span>Aperçu</span>
						<span className="link" onClick={open_website}>
							Voir sur le site
						</span>
					</div>
				)}
				<div className="preview-container-create-event">{children}</div>
			</div>
			<div className="navigation-container">
				<LoadingButton
					onClick={!can_save ? () => change_step(step.step + 1) : save_logic}
					loading={loading}
					variant={can_save ? "contained" : "outlined"}
					className={`save-next ${can_save ? "save" : "next"}`}
				>
					<span className="to-hide-on-load">{can_save ? "Sauvegarder" : "étape suivante"}</span>
					<i className="fas fa-chevron-right to-hide-on-load"></i>
				</LoadingButton>
			</div>
		</div>
	);
};

export default CreateUpdateAside;
