import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import AsideNavbar from "./components/nav/aside_navbar/AsideNavbar";
import Navbar from "./components/nav/navbar/Navbar";
import AuthContext from "./contexts/AuthContext";
import { ConfirmationProvider } from "./contexts/ConfirmationContext";
import Log from "./Log";
import Main from "./Main";
import { createTheme, ThemeProvider } from "@mui/material";
import { mapDispatchToProps } from "./store/dispatcher";
import { CurrentEventProvider } from "./contexts/CurrentEventContext";
import { ConfigProvider } from "antd";
import { App as AntdApp } from "antd";
import frFR from "antd/locale/fr_FR";

const App = ({ fetchEvents }) => {
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user.token && user.account) {
			fetchEvents(user.account.id);
		}
	}, [user]);

	let color_theme = localStorage.getItem("theme");
	color_theme = color_theme ? color_theme : "dark";
	const theme = {
		token: {
			colorPrimary: "#e98862",
		},
	};

	if (user.token && user.token != "") {
		return (
			<ConfigProvider locale={frFR} theme={theme}>
				<HashRouter>
					<Navbar />
					<AsideNavbar />
					<ConfirmationProvider>
						<AntdApp>
							<CurrentEventProvider>
								<div id="main-content" className="content">
									<Main user={user} />
								</div>
							</CurrentEventProvider>
						</AntdApp>
					</ConfirmationProvider>
				</HashRouter>
			</ConfigProvider>
		);
	}
	return (
		<ConfigProvider
			theme={{
				token: {
					// Seed Token
					colorPrimary: "#00b96b",
				},
			}}
		>
			<HashRouter>
				<ConfirmationProvider>
					<AntdApp>
						<Log />
					</AntdApp>
				</ConfirmationProvider>
			</HashRouter>
		</ConfigProvider>
	);
};

export default connect(null, mapDispatchToProps)(App);
