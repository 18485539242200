import React, { useContext } from "react";
import "./style.scss";
import { generate_image_path } from "helpers/images";
import AuthContext from "contexts/AuthContext";
import { replace_titles_description } from "helpers/format";

const getTextColorForBackground = (hexColor) => {
	// Convert hex color to RGB
	const r = parseInt(hexColor.substr(1, 2), 16);
	const g = parseInt(hexColor.substr(3, 2), 16);
	const b = parseInt(hexColor.substr(5, 2), 16);

	// Calculate the luminance
	const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

	// If luminance is lower than a threshold, use white, otherwise black
	return luminance < 140 ? "white" : "black";
};

const generate_detail_picture = (main_picture, event, show_button) => {
	if (main_picture) {
		return main_picture[1];
	}
	if (show_button && event.main_picture_64) {
		return event.main_picture_64[1];
	}
	return generate_image_path(event.id, event.main_picture, "phone");
};

const GeneralDataPreview = ({ data, event, main_picture, show_button = false }) => {
	const { user } = useContext(AuthContext);

	return (
		<div className="general-data-preview">
			<div
				className="draft"
				style={{
					color: event.status == "draft" || !event.status ? "var(--clr-deactivate)" : "var(--clr-success)",
					borderColor:
						event.status == "draft" || !event.status ? "var(--clr-deactivate)" : "var(--clr-success)",
				}}
			>
				<span>{event.status == "draft" || !event.status ? "Brouillon" : "Publié"}</span>
			</div>
			<div className="image-top">
				{event.id || main_picture ? (
					<img className="background" src={generate_detail_picture(main_picture, event, show_button)} />
				) : (
					<div className="background empty"></div>
				)}
				{event.id || main_picture ? (
					<img className="cover" src={generate_detail_picture(main_picture, event, show_button)} />
				) : (
					<div className="cover empty"></div>
				)}
			</div>

			<div className="event-content">
				<span
					style={data.title == "" ? { width: "40%" } : {}}
					className={`event-title ${data.title == "" ? "empty" : ""}`}
				>
					{data.title}
				</span>
				<span
					style={data.small_description == "" ? { width: "80%", height: "20px" } : {}}
					className={`event-small-description ${data.small_description == "" ? "empty" : ""}`}
				>
					{data.small_description}
				</span>
				<div className="organizer-logo">
					<img src={generate_image_path(user.account.id, user.account.main_picture, "small", true)} />
					<span>{user.account.title}</span>
				</div>
				<div
					className={`event-description ${data.description == "" ? "empty" : ""}`}
					dangerouslySetInnerHTML={{
						__html: replace_titles_description(data.description),
					}}
				/>
				{show_button && (
					<div className="button-container">
						<div
							className="btn"
							style={{
								color: getTextColorForBackground(data.primary_color),
								backgroundColor: data.primary_color,
							}}
						>
							Réserver
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default GeneralDataPreview;
