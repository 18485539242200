// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-empty {
  width: 100%;
  height: calc(100vh - 60px);
  padding-top: 40px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-empty h1 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.page-empty h1,
.page-empty p {
  max-width: 80%;
  text-align: center;
}
.page-empty .center-animation {
  width: 30%;
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/main/empty_view/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,0BAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,gBAAA;EACA,mBAAA;AACF;AAEC;;EAEC,cAAA;EACA,kBAAA;AAAF;AAGC;EACC,UAAA;EACA,gBAAA;AADF","sourcesContent":[".page-empty {\n\twidth: 100%;\n\theight: calc(100vh - 60px);\n\tpadding-top: 40px;\n\tpadding-left: 60px;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\n\th1 {\n\t\tmargin-top: 20px;\n\t\tmargin-bottom: 10px;\n\t}\n\n\th1,\n\tp {\n\t\tmax-width: 80%;\n\t\ttext-align: center;\n\t}\n\n\t.center-animation {\n\t\twidth: 30%;\n\t\tmax-width: 200px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
