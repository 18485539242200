import React, { useContext, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";

const ToggleThemeButton = () => {
	const theme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark";
	const [colorTheme, setColorTheme] = useState(localStorage.getItem("theme"));
	const { toggle_theme } = useContext(AuthContext);

	const toggle_color_theme = () => {
		const html = document.getElementsByTagName("html")[0];
		if (colorTheme === "dark") {
			html.classList.remove("dark");
			localStorage.setItem("theme", "light");
		} else {
			html.classList.add("dark");
			localStorage.setItem("theme", "dark");
		}
		setColorTheme(colorTheme === "dark" ? "light" : "dark");
		toggle_theme(colorTheme === "dark" ? "light" : "dark");
	};

	return (
		<div id="toggle-theme-wrapper">
			<div className="button-wrapper">
				<input
					checked={colorTheme === "dark"}
					onChange={toggle_color_theme}
					type="checkbox"
					className="dn"
					id="dn"
				/>
				<label htmlFor="dn" className="toggle">
					<span className="toggle__handler">
						<span className="crater crater--1"></span>
						<span className="crater crater--2"></span>
						<span className="crater crater--3"></span>
					</span>
					<span className="star star--1"></span>
					<span className="star star--2"></span>
					<span className="star star--3"></span>
					<span className="star star--4"></span>
					<span className="star star--5"></span>
					<span className="star star--6"></span>
				</label>
			</div>
		</div>
	);
};

export default ToggleThemeButton;
