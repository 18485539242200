// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#event-participants {
  padding: 30px;
  width: calc(100% - 35px);
  margin: 50px auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
  position: relative;
}
#event-participants .export-form {
  display: flex;
  position: absolute;
  top: 15px;
  right: 20px;
}
#event-participants .export-form span {
  text-decoration: underline;
  cursor: pointer;
}
#event-participants a {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/screens/events/event_participants/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,wBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,6CAAA;EACA,kBAAA;AACD;AACC;EACC,aAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AACF;AACE;EACC,0BAAA;EACA,eAAA;AACH;AAGC;EACC,YAAA;AADF","sourcesContent":["#event-participants {\n\tpadding: 30px;\n\twidth: calc(100% - 35px);\n\tmargin: 50px auto;\n\tbackground-color: white;\n\tborder-radius: 10px;\n\tbox-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);\n\tposition: relative;\n\n\t.export-form {\n\t\tdisplay: flex;\n\t\tposition: absolute;\n\t\ttop: 15px;\n\t\tright: 20px;\n\n\t\tspan {\n\t\t\ttext-decoration: underline;\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\ta {\n\t\tcolor: black;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
