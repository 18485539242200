// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#event-ticket {
  width: 100%;
  padding: 30px;
  width: calc(100% - 35px);
  margin: 50px auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
}
#event-ticket a {
  color: black;
}

.passpass-single-pages {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.passpass-single-pages .divider {
  display: block;
  width: 100%;
  background-color: var(--clr-divider);
  height: 0.5px;
  margin: 20px 0;
}
.passpass-single-pages .single-pages-block .title {
  font-size: 1.2rem;
  font-weight: bold;
}
.passpass-single-pages .single-pages-block .info-container {
  display: flex;
  align-items: flex-start;
}
.passpass-single-pages .single-pages-block .info-container .info-item:not(:first-child) {
  position: relative;
  padding: 0 20px;
}
.passpass-single-pages .single-pages-block .info-container .info-item:not(:first-child)::before {
  content: "";
  display: block;
  width: 0.5px;
  height: 100%;
  background-color: var(--clr-divider);
  position: absolute;
  top: 0;
  left: 0;
}
.passpass-single-pages .single-pages-block .info-container .info-item:first-child {
  padding-right: 20px;
}
.passpass-single-pages .single-pages-block .info-container .info-item .info-name {
  display: block;
  color: var(--clr-secondary-text);
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/screens/events/event_ticket/style.scss"],"names":[],"mappings":"AAAA;EAEC,WAAA;EAEA,aAAA;EACA,wBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,6CAAA;AAAD;AAEC;EACC,YAAA;AAAF;;AAIA;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AADD;AAGC;EACC,cAAA;EACA,WAAA;EACA,oCAAA;EACA,aAAA;EACA,cAAA;AADF;AAKE;EACC,iBAAA;EACA,iBAAA;AAHH;AAME;EACC,aAAA;EACA,uBAAA;AAJH;AAOI;EACC,kBAAA;EACA,eAAA;AALL;AAOK;EACC,WAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,oCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AALN;AASI;EACC,mBAAA;AAPL;AAUI;EACC,cAAA;EACA,gCAAA;EACA,kBAAA;AARL","sourcesContent":["#event-ticket {\n\tpadding: 20px;\n\twidth: 100%;\n\n\tpadding: 30px;\n\twidth: calc(100% - 35px);\n\tmargin: 50px auto;\n\tbackground-color: white;\n\tborder-radius: 10px;\n\tbox-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);\n\n\ta {\n\t\tcolor: black;\n\t}\n}\n\n.passpass-single-pages {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 40px;\n\n\t.divider {\n\t\tdisplay: block;\n\t\twidth: 100%;\n\t\tbackground-color: var(--clr-divider);\n\t\theight: 0.5px;\n\t\tmargin: 20px 0;\n\t}\n\n\t.single-pages-block {\n\t\t.title {\n\t\t\tfont-size: 1.2rem;\n\t\t\tfont-weight: bold;\n\t\t}\n\n\t\t.info-container {\n\t\t\tdisplay: flex;\n\t\t\talign-items: flex-start;\n\n\t\t\t.info-item {\n\t\t\t\t&:not(:first-child) {\n\t\t\t\t\tposition: relative;\n\t\t\t\t\tpadding: 0 20px;\n\n\t\t\t\t\t&::before {\n\t\t\t\t\t\tcontent: \"\";\n\t\t\t\t\t\tdisplay: block;\n\t\t\t\t\t\twidth: 0.5px;\n\t\t\t\t\t\theight: 100%;\n\t\t\t\t\t\tbackground-color: var(--clr-divider);\n\t\t\t\t\t\tposition: absolute;\n\t\t\t\t\t\ttop: 0;\n\t\t\t\t\t\tleft: 0;\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\t&:first-child {\n\t\t\t\t\tpadding-right: 20px;\n\t\t\t\t}\n\n\t\t\t\t.info-name {\n\t\t\t\t\tdisplay: block;\n\t\t\t\t\tcolor: var(--clr-secondary-text);\n\t\t\t\t\tmargin-bottom: 5px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
