// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#event-participant {
  padding: 30px;
  width: calc(100% - 35px);
  margin: 50px auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
}
#event-participant a {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/screens/events/event_participant/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,wBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,6CAAA;AACD;AACC;EACC,YAAA;AACF","sourcesContent":["#event-participant {\n\tpadding: 30px;\n\twidth: calc(100% - 35px);\n\tmargin: 50px auto;\n\tbackground-color: white;\n\tborder-radius: 10px;\n\tbox-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);\n\n\ta {\n\t\tcolor: black;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
