// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#account-members {
  width: 100%;
}
#account-members .table-container-users {
  width: 100%;
  max-width: 800px;
  border: 1px solid var(--clr-divider);
  margin-top: 20px;
  border-radius: var(--border-radius);
}
#account-members.full-width .table-container-users {
  max-width: 1200px;
}
#account-members table {
  width: 100%;
  border-collapse: collapse;
}
#account-members table thead tr th {
  padding: 10px;
}
#account-members table tbody tr td {
  border-top: 1px solid var(--clr-divider);
  padding: 10px;
}
#account-members table tbody tr td.delete {
  width: 160px;
  padding: 0;
  padding-right: 10px;
}
#account-members table tbody tr td.delete svg {
  cursor: pointer;
}
#account-members table tbody tr td.delete svg:hover {
  fill: var(--clr-error);
}
#account-members table tbody tr td .user-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#account-members table tbody tr td .user-name-container span {
  display: block;
  margin-left: 10px;
  background-color: var(--clr-surface);
  padding: 4px 6px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/screens/account_settings/members/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,WAAA;EACA,gBAAA;EACA,oCAAA;EACA,gBAAA;EACA,mCAAA;AACF;AAGE;EACC,iBAAA;AADH;AAKC;EACC,WAAA;EACA,yBAAA;AAHF;AAOI;EACC,aAAA;AALL;AAYI;EACC,wCAAA;EACA,aAAA;AAVL;AAYK;EACC,YAAA;EACA,UAAA;EACA,mBAAA;AAVN;AAYM;EACC,eAAA;AAVP;AAYO;EACC,sBAAA;AAVR;AAeK;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;AAbN;AAcM;EACC,cAAA;EACA,iBAAA;EACA,oCAAA;EACA,gBAAA;EACA,kBAAA;AAZP","sourcesContent":["#account-members {\n\twidth: 100%;\n\n\t.table-container-users {\n\t\twidth: 100%;\n\t\tmax-width: 800px;\n\t\tborder: 1px solid var(--clr-divider);\n\t\tmargin-top: 20px;\n\t\tborder-radius: var(--border-radius);\n\t}\n\n\t&.full-width {\n\t\t.table-container-users {\n\t\t\tmax-width: 1200px;\n\t\t}\n\t}\n\n\ttable {\n\t\twidth: 100%;\n\t\tborder-collapse: collapse;\n\n\t\tthead {\n\t\t\ttr {\n\t\t\t\tth {\n\t\t\t\t\tpadding: 10px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\ttbody {\n\t\t\ttr {\n\t\t\t\ttd {\n\t\t\t\t\tborder-top: 1px solid var(--clr-divider);\n\t\t\t\t\tpadding: 10px;\n\n\t\t\t\t\t&.delete {\n\t\t\t\t\t\twidth: 160px;\n\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\tpadding-right: 10px;\n\n\t\t\t\t\t\tsvg {\n\t\t\t\t\t\t\tcursor: pointer;\n\n\t\t\t\t\t\t\t&:hover {\n\t\t\t\t\t\t\t\tfill: var(--clr-error);\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\n\t\t\t\t\t.user-name-container {\n\t\t\t\t\t\tdisplay: flex;\n\t\t\t\t\t\tflex-direction: row;\n\t\t\t\t\t\talign-items: center;\n\t\t\t\t\t\tspan {\n\t\t\t\t\t\t\tdisplay: block;\n\t\t\t\t\t\t\tmargin-left: 10px;\n\t\t\t\t\t\t\tbackground-color: var(--clr-surface);\n\t\t\t\t\t\t\tpadding: 4px 6px;\n\t\t\t\t\t\t\tborder-radius: 4px;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
