import React from "react";
import "styles/dashboard.scss";

const CellStatus = ({ status, label_list, color_list, icon_list = null }) => {
	return (
		<span
			className={`table-value-status ${icon_list ? "icon" : ""}`}
			style={{ backgroundColor: color_list[status] }}
		>
			{label_list[status]}
			{icon_list && <i className={icon_list[status]}></i>}
		</span>
	);
};

export default CellStatus;
