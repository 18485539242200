// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passpass-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.passpass-modal .modal-center {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  width: 90%;
  max-width: 800px;
}
.passpass-modal .modal-center .modal-header {
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--clr-divider);
}
.passpass-modal .modal-center .modal-header span {
  font-size: 1.2rem;
  font-weight: bold;
}
.passpass-modal .modal-center .modal-footer {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/main/modal/style.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,oCAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,gBAAA;AACF;AACE;EACC,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,2CAAA;AACH;AACG;EACC,iBAAA;EACA,iBAAA;AACJ;AAGE;EACC,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,SAAA;AADH","sourcesContent":[".passpass-modal {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\tbottom: 0;\n\tright: 0;\n\tbackground-color: rgba($color: #000000, $alpha: 0.3);\n\tz-index: 10000;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\t.modal-center {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tbackground-color: #fff;\n\t\tpadding: 30px;\n\t\tborder-radius: 20px;\n\t\twidth: 90%;\n\t\tmax-width: 800px;\n\n\t\t.modal-header {\n\t\t\twidth: 100%;\n\t\t\tpadding-bottom: 20px;\n\t\t\tmargin-bottom: 20px;\n\t\t\tborder-bottom: 1px solid var(--clr-divider);\n\n\t\t\tspan {\n\t\t\t\tfont-size: 1.2rem;\n\t\t\t\tfont-weight: bold;\n\t\t\t}\n\t\t}\n\n\t\t.modal-footer {\n\t\t\tpadding-top: 20px;\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\talign-items: center;\n\t\t\tjustify-content: flex-end;\n\t\t\twidth: 100%;\n\t\t\tgap: 20px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
