import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";
import DashboardIcon from "../../../assets/static/dashboard-icon-2.svg";
import EventlistIcon from "../../../assets/static/event-list-icon.svg";
import OrganisationIcon from "../../../assets/static/team-icon.svg";
import UsersOrganisationIcon from "../../../assets/static/users-organisation.svg";
import LogoutIcon from "../../../assets/static/logout-icon.svg";
import NewEvent from "../../../assets/navigation/tab.png";
import Website from "../../../assets/navigation/world-wide-web.png";

const NavbarItem = ({ pathname, icon, title }) => {
	const location = useLocation();
	return (
		<li className={location.pathname === pathname ? "active hov" : "hov"}>
			<Link to={pathname}>
				<div className="navigation_elem">
					<img src={icon} alt={title} />
					<p>{title}</p>
				</div>
			</Link>
		</li>
	);
};

const AsideNavbar = () => {
	const location = useLocation();
	const { user, logout } = useContext(AuthContext);

	return (
		<aside id="aside-navbar" className="navigation">
			<nav id="navbar">
				<ul>
					<NavbarItem title={"Dashboard"} pathname={"/"} icon={DashboardIcon} />

					<li>
						<div className="separator">
							<h2 className="separator_title">événements</h2>
						</div>
					</li>
					<NavbarItem
						title={"Mes événements"}
						pathname={"/events"}
						icon={EventlistIcon}
					/>
					<NavbarItem
						title={"Créer un événement"}
						pathname={"/create-event"}
						icon={NewEvent}
					/>

					<li>
						<div className="separator">
							<h2 className="separator_title">Organisation</h2>
						</div>
					</li>
					<li
						className={`large ${
							location.pathname == "/organization-profile" ? "active hov" : "hov"
						}`}
					>
						<Link to="/organization-profile">
							<div className="navigation_elem">
								<img src={UsersOrganisationIcon} alt="Organisation users Icon" />
								<p>Profil</p>
							</div>
						</Link>
					</li>
					<li
						className={`large ${
							location.pathname.includes("/account-settings") ? "active hov" : "hov"
						}`}
					>
						<Link to="/account-settings">
							<div className="navigation_elem">
								<img src={OrganisationIcon} alt="Organisation Icon" />
								<p>Gestion</p>
							</div>
						</Link>
					</li>
					<li
						className={`small ${
							location.pathname.includes("/account-settings") &&
							location.pathname !== "/account-settings/developers"
								? "active hov"
								: "hov"
						}`}
					>
						<Link to="/account-settings/navigation">
							<div className="navigation_elem">
								<img src={DashboardIcon} alt="Dashboard Icon" />
								<p>Account settings</p>
							</div>
						</Link>
					</li>
					<li>
						<div className="separator"></div>
					</li>
					<li className="hov">
						<Link target="_blank" to={process.env.REACT_APP_SITE_URL}>
							<div className="navigation_elem">
								<img src={Website} alt="Logout Icon" />
								<p>Site web</p>
							</div>
						</Link>
					</li>
					<li className={location.pathname === "/logout" ? "active hov" : "hov"}>
						<Link to="/" onClick={logout}>
							<div className="navigation_elem">
								<img src={LogoutIcon} alt="Logout Icon" />
								<p>Déconnexion</p>
							</div>
						</Link>
					</li>
				</ul>
			</nav>
		</aside>
	);
};

export default AsideNavbar;
