import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import Arrow from "../../../assets/dashboard/arrow.svg";

const DashboardBlock = ({
	title,
	link_label,
	link_href,
	type = "transparent",
	width = 1,
	children,
}) => {
	return (
		<div className={`dashboard-block-container ${width == 1 ? "small" : "large"}`}>
			<div className="dashboard-block-header">
				<span className="title">{title}</span>
				<Link
					className="block-main-link"
					to={link_href}
					target={link_href.indexOf("https") != -1 ? "_blank" : "_self"}
				>
					<span>{link_label}</span>
					<img src={Arrow} />
				</Link>
			</div>
			<div
				className="dashboard-block-content"
				style={{ backgroundColor: type == "transparent" ? "transparent" : "#fcfbfb" }}
			>
				{children}
			</div>
		</div>
	);
};

export default DashboardBlock;
