import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

let color_theme = localStorage.getItem("theme");
if (!color_theme) {
	color_theme = "light";
	localStorage.setItem("theme", "light");
}

if (color_theme === "dark") {
	document.getElementsByTagName("html")[0].classList.add("dark");
} else {
	document.getElementsByTagName("html")[0].classList.remove("dark");
}

root.render(
	<Provider store={store}>
		<AuthProvider>
			<App />
		</AuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
