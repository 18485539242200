import React, { useContext, useEffect, useRef, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import AuthContext from "../../../contexts/AuthContext";
import axios from "../../../services/axios";
import { Autocomplete, Button, TextField } from "@mui/material";
import { TOAST_LVL } from "../../../constants/constants";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { useParams } from "react-router-dom";
import NewMember from "../../../components/account_settings/members/new_member/NewMember";
import "./style.scss";
import addIcon from "../../../../src/assets/static/add-icon.svg";
import { App } from "antd";

const EventMembers = ({ events }) => {
	const { user } = useContext(AuthContext);
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const [newUser, setNewUser] = useState(false);
	const [members, setMembers] = useState([]);
	const event = useRef(null);
	const { event_id } = useParams();
	const current_event_exist = events.filter((elem) => elem.id === event_id);
	const current_event = current_event_exist.length ? current_event_exist[0] : { title: "", main_picture: "" };
	const { notification } = App.useApp();

	const load_settings = async () => {
		const response = await axios
			.get(`/api/events/${event_id}/accesses`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			event.current = response;
			setMembers(JSON.parse(JSON.stringify(response)));
		}
	};

	useEffect(() => {
		load_settings();
	}, []);

	const delete_user = async (delete_id, name) => {
		const ret = await axios
			.delete(`/api/events/${event_id}/accesses/${delete_id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			notification.success({
				message: "Member deleted",
				description: `The access rights on ${current_event.title} to ${name} have been revoked`,
				placement: "bottomLeft",
			});
		} else {
			notification.error({
				message: "Deletion error",
				description: `You do not have sufficient rights to perform this action`,
				placement: "bottomLeft",
			});
		}
		load_settings();
	};

	const save = async () => {
		const to_update = [];
		for (let index = 0; index < members.length; index++) {
			const member = members[index];
			const equivalent = event.current.filter((e) => e.id === member.id);
			if (equivalent.length && equivalent[0].role != member.role) {
				to_update.push(member);
			}
		}

		let error = false;

		for (let index = 0; index < to_update.length; index++) {
			const element = to_update[index];
			const ret = await axios
				.put(`/api/events/${event_id}/accesses`, {
					role: element.role,
					profile: element.profile,
				})
				.then((e) => e.data)
				.catch((e) => {
					error = true;
					return null;
				});
		}
		if (!error) {
			notification.success({
				message: "Access updated",
				description: `The access rights on ${current_event.title} have been updated`,
				placement: "bottomLeft",
			});
		} else {
			notification.success({
				message: "Update error",
				description: `You do not have sufficient rights to perform this action`,
				placement: "bottomLeft",
			});
		}
		load_settings();
	};

	const reset = () => {
		setMembers(JSON.parse(JSON.stringify(event.current)));
	};

	const valid = () => {
		return members.length && JSON.stringify(members) !== JSON.stringify(event.current);
	};

	const handleChange = (event, id) => {
		const new_value = members.map((elem) => {
			if (elem.id === id) {
				elem.role = event.target.value;
			}
			return elem;
		});
		setMembers(new_value);
	};
	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div id="account-members" className="event-content full-width">
				<div className="intro-content">
					<h1>Gérez l'équipe</h1>
					<p>
						Cette page vous permet de gérer les rôles et accès des utilisateurs internes à cet événement
						spécifique.
					</p>
				</div>
				<div className="table-container-users">
					<table>
						<thead>
							<tr>
								<th align="start">Utilisateur</th>
								<th align="start">Rôle</th>
								<th className="delete">
									<Button onClick={() => setNewUser(true)} className="create-event-btn">
										<img src={addIcon} alt="" />
										<span>Ajouter un membre</span>
									</Button>
								</th>
							</tr>
						</thead>
						<tbody>
							{members.map((elem, id) => (
								<tr key={id}>
									<td>
										<div className="user-name-container">
											<p>
												{elem.first_name} {elem.last_name}{" "}
												{elem.profile === user.id ? "(you)" : ""}
											</p>
											{elem.id === user.account.owner ? (
												<span className="is_admin">Administrator</span>
											) : null}
										</div>
									</td>
									<td>
										<Autocomplete
											multiple
											id="tags-standard"
											options={["team_manager", "event_manager", "validator"]}
											getOptionLabel={(option) =>
												option.charAt(0).toUpperCase() + option.slice(1).replaceAll("_", " ")
											}
											onChange={(event, value) => {
												const members_copy = [...members];
												members_copy[id].role = value.join("|");

												setMembers(members_copy);
											}}
											value={
												elem.role === ""
													? []
													: elem.role
															.trim()
															.split("|")
															.map((elem) => elem)
											}
											renderInput={(params) => (
												<TextField {...params} variant="outlined" placeholder="Roles" />
											)}
										/>
									</td>
									{elem.id === user.account.owner ? (
										<td></td>
									) : (
										<td
											onClick={() =>
												open_confirm(
													`Supprimer l'accès à "${elem.first_name} ${elem.last_name}"`,
													"Il s'agit d'une action destructrice. Tous les droits de cet utilisateur seront révoqués													",
													`Supprimer`,
													() =>
														delete_user(
															elem.profile,
															`${elem.last_name} ${elem.first_name}`
														)
												)
											}
											className="delete"
											align="end"
										>
											<DeleteOutlineIcon />
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<NewMember
				add_member={load_settings}
				close={() => setNewUser(false)}
				active={newUser}
				is_event={true}
				event_id={event_id}
			/>
		</DefaultView>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventMembers);
