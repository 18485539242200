import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

const Demo = () => {
	return (
		<div id="demo">
			<div className="demo-center">
				<h1>Dashboard PassPass</h1>
				<p>
					Cette application est en version bêta et n'est accessible qu'à nos utilisateurs
					de test. Si vous voulez rejoindre cette phase de test, n'hésitez pas à nous en
					parler !
				</p>
				<div className="links-container">
					<Link className="home" to={"https://passpass.be/create-event"}>
						Obtenir une démo
					</Link>
					<Link to={"https://passpass.be"}>Retourner sur passpass.be</Link>
				</div>
			</div>
		</div>
	);
};

export default Demo;
