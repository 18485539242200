import React from "react";
import "./style.scss";
import Lottie from "lottie-react";
import empty from "assets/lottie/empty.json";

const EmptyView = ({ height = "100%", title, description }) => {
	return (
		<div className="page-empty" style={{ height: height }}>
			<Lottie className="center-animation" animationData={empty} />
			<h1>{title}</h1>
			<p>{description}</p>
		</div>
	);
};

export default EmptyView;
