import React, { useContext, useEffect, useRef } from "react";
import axios from "../../../../services/axios";
import AuthContext from "contexts/AuthContext";

const RefreshStripeLink = () => {
	const { user } = useContext(AuthContext);
	const first = useRef(true);

	const generate_stripe_link = async () => {
		const ret = await axios
			.post(`/api/organizers/${user.account.id}/connected-account`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			window.location.href = ret;
		}
	};

	useEffect(() => {
		if (user.account.id && first.current) {
			first.current = false;
			generate_stripe_link();
		}
	}, [user]);

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				padding: "80px",
			}}
		>
			Regénération du lien stripe
		</div>
	);
};

export default RefreshStripeLink;
