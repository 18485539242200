export const format_price = (price) => {
	return `${(price / 100).toFixed(2)} €`;
};

const capitalizeSentence = (sentence) => {
	let words = sentence.split(" ");
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
	}
	return words.join(" ");
};

export const format_time_range = (startTime, endTime) => {
	if (typeof startTime == "string") {
		startTime = new Date(startTime);
	}
	if (typeof endTime == "string") {
		endTime = new Date(endTime);
	}
	const options = {
		hour: "numeric",
		minute: "numeric",
	};

	let startTimeString = startTime.toLocaleTimeString("fr-FR", options).replace(/:\d{2}\s/, " - ");
	let endTimeString = endTime.toLocaleTimeString("fr-FR", options).replace(/:\d{2}\s/, "");

	return startTimeString + " - " + endTimeString;
};

export const format_time = (time) => {
	if (typeof time == "string") {
		time = new Date(time);
	}
	const options = {
		hour: "numeric",
		minute: "numeric",
	};

	let timeString = time.toLocaleTimeString("fr-FR", options).replace(/:\d{2}\s/, " - ");

	return timeString;
};

export const format_date = (date, format = "") => {
	if (typeof date == "string") {
		date = new Date(date);
	}
	let options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	if (format == "full_no_weekday") {
		options = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};
	} else if (format == "full_short") {
		options = {
			weekday: "short",
			year: "numeric",
			month: "short",
			day: "numeric",
		};
	} else if (format == "full_short_no_date") {
		options = {
			month: "short",
			day: "numeric",
		};
	}

	let formated_date = date.toLocaleDateString("fr-FR", options);

	return capitalizeSentence(formated_date);
};

function areDatesOnSameDay(date1, date2) {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}

export const event_date_format = (event, format = "", no_hours = false) => {
	const start_at = typeof event.start_at == "string" ? new Date(event.start_at) : event.start_at;
	const end_at = typeof event.end_at == "string" ? new Date(event.end_at) : event.end_at;

	if (areDatesOnSameDay(start_at, end_at)) {
		let options = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		if (format == "small") {
			options = {
				year: "numeric",
				month: "long",
				day: "numeric",
			};
		}
		let formated_date = capitalizeSentence(start_at.toLocaleDateString("fr-FR", options));

		if (no_hours) return formated_date;

		return `${formated_date} ${format_time_range(start_at, end_at)}`;
	} else {
		let options = {
			year: "numeric",
			month: "short",
			day: "numeric",
		};
		let start_char = "Du ";
		let middle_char = " au ";

		if (no_hours) {
			return `${start_char}${capitalizeSentence(
				start_at.toLocaleDateString("fr-FR", options)
			)}${middle_char}${capitalizeSentence(end_at.toLocaleDateString("fr-FR", options))} `;
		}
		return `${start_char}${capitalizeSentence(start_at.toLocaleDateString("fr-FR", options))} ${format_time(
			start_at
		)}${middle_char}${capitalizeSentence(end_at.toLocaleDateString("fr-FR", options))} ${format_time(end_at)}`;
	}
};

export const replace_titles_description = (description) => {
	description = description.replaceAll("<h1>", "<span class='h1'>");
	description = description.replaceAll("</h1>", "</span>");

	description = description.replaceAll("<h2>", "<span class='h2'>");
	description = description.replaceAll("</h2>", "</span>");

	description = description.replaceAll("<h3>", "<span class='h3'>");
	description = description.replaceAll("</h3>", "</span>");

	description = description.replaceAll("<h4>", "<span class='h4'>");
	description = description.replaceAll("</h4>", "</span>");

	description = description.replaceAll("<h5>", "<span class='h5'>");
	description = description.replaceAll("</h5>", "</span>");

	description = description.replaceAll("<h6>", "<span class='h6'>");
	description = description.replaceAll("</h6>", "</span>");

	description = description.replaceAll("<a", "<a target='_blank'");
	return description;
};
