import {
	AlignLeftOutlined,
	CaretDownOutlined,
	CheckCircleOutlined,
	CheckOutlined,
	CheckSquareOutlined,
	DeleteOutlined,
	FileTextOutlined,
	FontSizeOutlined,
	HolderOutlined,
	InfoCircleOutlined,
	LinkOutlined,
	LoadingOutlined,
	QuestionCircleOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import { Popconfirm, Select, Tooltip, Button as AntdButton, Popover, Tabs } from "antd";
import EventCreationContext from "contexts/EventCreationContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "../../../../../services/axios";
import "./style.scss";
import { Button } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../ticket_wrapper/TicketWrapper";
import ParticipationFormModal from "./participation_form_modal/ParticipationFormModal";
import { generateRandomString } from "helpers/main";

const ParticipationForm = () => {
	const { event } = useContext(EventCreationContext);
	const [formData, setFormData] = useState({
		title: "",
		single_fill: true,
		questions: [],
		ticket_target: "all",
		event: 1,
		type: "before",
	});
	const [vueFilter, setVueFilter] = useState("all");
	const [selectedQuestion, setSelectedQuestion] = useState({
		item: null,
		new: false,
	});
	const [formType, setFormType] = useState("before");
	const save_timout = useRef(null);
	const [saveLoading, setSaveLoading] = useState(false);

	const targetOptions = [
		{
			label: "Tous les tickets",
			value: "all",
		},
		...event.tickets.map((ticket) => {
			return {
				label: ticket.title,
				value: ticket.id,
			};
		}),
	];

	const filterOptions = [
		{
			label: "Vue globale",
			value: "all",
		},
		...event.tickets.map((ticket) => {
			return {
				label: ticket.title,
				value: ticket.id,
			};
		}),
	];

	const handleTargetChange = (e) => {
		const all_index = e.indexOf("all");
		if (all_index == 0 && e.length > 1) {
			e.splice(all_index, 1);
		} else if (all_index == e.length - 1 && e.length > 1) {
			setFormData({ ...formData, ticket_target: "all" });
			return;
		}
		setFormData({ ...formData, ticket_target: e });
	};

	const save_form = async () => {
		if (formType != formData.type) {
			return;
		}
		const ret = await axios
			.put(`/api/events/${event.id}/participation-form/${formType}`, {
				title: formData.title,
				single_fill: formData.single_fill,
				questions: JSON.stringify(formData.questions),
				ticket_target: JSON.stringify(formData.ticket_target),
				event: formData.event,
				form_type: formType,
			})
			.then((e) => e.data)
			.catch((e) => null);
		setSaveLoading(false);
	};

	const get_form = async () => {
		const ret = await axios
			.get(`/api/events/${event.id}/participation-form`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			const specific_form = ret.find((e) => e.form_type == formType);
			if (specific_form) {
				setFormData({
					title: specific_form.title,
					single_fill: specific_form.single_fill,
					questions: JSON.parse(specific_form.questions),
					ticket_target:
						specific_form.ticket_target == "all" ? "all" : JSON.parse(specific_form.ticket_target),
					event: event.id,
					type: formType,
				});
			} else {
				setFormData({
					title: "",
					single_fill: true,
					questions: [],
					ticket_target: "all",
					event: 1,
					type: formType,
				});
			}
		} else {
			setFormData({
				title: "",
				single_fill: true,
				questions: [],
				ticket_target: "all",
				event: 1,
				type: formType,
			});
		}
	};

	useEffect(() => {
		if (save_timout.current) {
			clearTimeout(save_timout.current);
			setSaveLoading(true);
			save_timout.current = setTimeout(() => {
				save_form();
			}, 2000);
		} else {
			save_timout.current = setTimeout(() => {}, 100);
		}
		return () => {
			clearTimeout(save_timout.current);
		};
	}, [formData]);

	useEffect(() => {
		get_form();
		clearTimeout(save_timout.current);
		save_timout.current = null;
	}, [event, formType]);

	const field_types = {
		"short-text": (
			<span className="type">
				<FontSizeOutlined style={{ marginRight: "5px" }} /> Texte court
			</span>
		),
		text: (
			<span className="type">
				<AlignLeftOutlined style={{ marginRight: "5px" }} /> Paragraphe
			</span>
		),
		"unique-choice": (
			<span className="type">
				<CheckCircleOutlined style={{ marginRight: "5px" }} /> Choix unique
			</span>
		),
		"multiple-choice": (
			<span className="type">
				<CheckSquareOutlined style={{ marginRight: "5px" }} /> Choix multiple
			</span>
		),
		selection: (
			<span className="type">
				<CaretDownOutlined style={{ marginRight: "5px" }} /> Sélectionner
			</span>
		),
		"additional-conditions": (
			<span className="type">
				<FileTextOutlined style={{ marginRight: "5px" }} /> Condition supplémentaires
			</span>
		),
		"no-field": (
			<span>
				<AlignLeftOutlined style={{ marginRight: "5px" }} /> Texte sans champ
			</span>
		),
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(formData.questions, result.source.index, result.destination.index);

		setFormData({ ...formData, questions: items });
	};

	const createQuestion = (data) => {
		setFormData({ ...formData, questions: [...formData.questions, { ...data, id: generateRandomString(16) }] });
		setSelectedQuestion({ item: null, new: false });
	};

	const updateQuestion = (data) => {
		const question_index = formData.questions.findIndex((question) => question.id == data.id);
		if (question_index >= 0) {
			const questions = formData.questions;
			questions.splice(question_index, 1, data);
			setFormData({ ...formData, questions: questions });
		}
		setSelectedQuestion({ item: null, new: false });
	};

	return (
		<div className="participation-form-container">
			{saveLoading && (
				<div className="flex flex-row gap-3 items-center fixed bottom-5 right-[400px] z-10 bg-[#000] text-[#fff] px-2 py-1 shadow rounded-md pointer-events-none">
					Sauvegarde en cours <LoadingOutlined />
				</div>
			)}
			<Tabs
				items={[
					{ key: "before", label: "Formulaire pré commande", children: null },
					{ key: "after", label: "Formulaire post commande", children: null },
				]}
				style={{ width: "100%", marginBottom: "-20px" }}
				onChange={setFormType}
			/>
			<ParticipationFormModal
				tickets={event.tickets}
				questions={formData.questions}
				create={createQuestion}
				update={updateQuestion}
				selected={selectedQuestion}
				close={() => setSelectedQuestion({ item: null, new: false })}
			/>
			<div className="form-description">
				{formType == "before" ? (
					<p>
						L'objectif du <span>formulaire pré commande</span> est de récolter des informations sur vos
						participants <span>avant</span> l'acte d'achat. Cela permet d'ajouter des conditions d'achat, de
						permettre aux sociétés d'ajouter un numéro TVA...
						<br />
						Evitez de rendre ce formulaire trop long car cela pourrait freiner l'achat
					</p>
				) : (
					<p>
						L'objectif du <span>formulaire post commande</span> est de récolter des informations sur vos
						participants <span>aprés</span> l'acte d'achat. L'avantage de ce formulaire est que vos
						participants ont déjà payé, cela ne freine pas l'achat.
					</p>
				)}
			</div>
			<div className="row">
				<div
					onClick={() => setFormData({ ...formData, single_fill: true })}
					className={`fill-selection ${formData.single_fill ? "selected" : ""}`}
				>
					<div className="fill-selection-header">
						<div className="icon-container">
							<UserOutlined style={{ fontSize: "18px" }} />
						</div>
						<span>Uniquement informations sur l'acheteur</span>
					</div>
					<span>
						Rassemblez uniquement les détails de la personne achetant les billets pour un processus plus
						rapide.
					</span>
				</div>
				<div
					onClick={() => setFormData({ ...formData, single_fill: false })}
					className={`fill-selection ${formData.single_fill ? "" : "selected"}`}
				>
					<div className="fill-selection-header">
						<div className="icon-container">
							<UsergroupAddOutlined style={{ fontSize: "18px" }} />
						</div>
						<span>Informations de chaque billet</span>
					</div>
					<span>Recueillez des informations spécifiques pour chaque billet commandé.</span>
				</div>
			</div>
			{/* {!formData.single_fill && (
				<div className="target-container">
					<span>Appliquer le formulaire de commande sur:</span>
					<Select
						mode="multiple"
						style={{ width: "100%" }}
						placeholder="Please select"
						value={formData.ticket_target == "all" ? ["all"] : formData.ticket_target}
						onChange={handleTargetChange}
						options={targetOptions}
						className="select-item"
					/>
				</div>
			)} */}
			<div className="field-block">
				<div className="field-header">
					<div onClick={() => setSelectedQuestion({ item: "", new: true })} className="global-fill-btn">
						Nouveau champ
					</div>
					{!formData.single_fill && (
						<div className="filter-container">
							<span>
								Filtrer la vue par ticket{" "}
								<Tooltip title="Le filtre de vue par ticket vous permet de verifier que le parcours utilisateur est logique pour chaque ticket.">
									<InfoCircleOutlined style={{ marginLeft: "10px" }} />
								</Tooltip>
							</span>
							<Select
								style={{ width: "100%" }}
								placeholder="Please select"
								value={vueFilter}
								onChange={(e) => setVueFilter(e)}
								options={filterOptions}
								className="select-item"
							/>
						</div>
					)}
				</div>
				<div className="field-container">
					<span>Champs du formulaire</span>
					{formType == "before" && (
						<div className="field-item disabled">
							<div className="field-item-left part">
								<div className="order-container">
									<HolderOutlined style={{ fontSize: "18px" }} />
								</div>
								<div className="field-description">
									<span>Nom & Email</span>
									<span className="type">Champ texte</span>
								</div>
								<span className="default-item">
									Par défaut
									<Tooltip title="Ces champs sont obligatoires afin d'identifier l'acheteur">
										<InfoCircleOutlined style={{ marginLeft: "10px" }} />
									</Tooltip>
								</span>
							</div>
							<div className="field-item-right part">
								<div className="mandatory-container">
									<span>Obligatoire</span>
								</div>
								<AntdButton danger icon={<DeleteOutlined style={{ color: "#f55956" }} />} />
							</div>
						</div>
					)}
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div className="questions-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
									{formData.questions.map((question, id) => {
										if (
											vueFilter !== "all" &&
											question.ticket_target.length &&
											!question.ticket_target.includes(vueFilter)
										)
											return null;
										return (
											<Draggable
												key={question.id.toString()}
												draggableId={question.id.toString()}
												index={id}
											>
												{(provided, snapshot) => {
													let can_click = true;
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															onClick={() => {
																if (can_click) {
																	setSelectedQuestion({
																		item: question.id,
																		new: false,
																	});
																}
																can_click = true;
															}}
															className="field-item"
														>
															<div className="field-item-left part">
																<div
																	className="order-container"
																	{...provided.dragHandleProps}
																>
																	<HolderOutlined style={{ fontSize: "18px" }} />
																</div>
																<div className="field-description">
																	<span>
																		{question.allow_target &&
																			question.ticket_target.length !== 0 && (
																				<Popover
																					content={
																						<div>
																							{question.ticket_target.map(
																								(ticket, id) => {
																									const ticket_data =
																										event.tickets.find(
																											(t) =>
																												t.id ==
																												ticket
																										);
																									if (!ticket_data)
																										return null;
																									return (
																										<p key={id}>
																											<LinkOutlined
																												style={{
																													marginRight:
																														"5px",
																													color: "#4589ff",
																												}}
																											/>
																											{
																												ticket_data.title
																											}
																										</p>
																									);
																								}
																							)}
																						</div>
																					}
																					title="Tickets liés"
																				>
																					<span className="linked-count">
																						<LinkOutlined />{" "}
																						{question.ticket_target.length}
																					</span>
																				</Popover>
																			)}
																		{question.title}
																	</span>
																	{field_types[question.type]}
																</div>
															</div>
															<div className="field-item-right part">
																{question.mandatory && (
																	<div className="mandatory-container">
																		<span>Obligatoire</span>
																	</div>
																)}
																<Popconfirm
																	title="Supprimer le champ"
																	description="Êtes-vous sûr de vouloir supprimer ce champ?"
																	onConfirm={() => {
																		can_click = false;
																		const questions = formData.questions;
																		questions.splice(id, 1);
																		setFormData({
																			...formData,
																			questions: questions,
																		});
																	}}
																	onCancel={() => (can_click = false)}
																	okText="Oui"
																	icon={
																		<QuestionCircleOutlined
																			style={{ color: "red" }}
																		/>
																	}
																	cancelText="Non"
																>
																	<AntdButton
																		onClick={() => (can_click = false)}
																		danger
																		icon={
																			<DeleteOutlined
																				style={{ color: "#f55956" }}
																			/>
																		}
																	/>
																</Popconfirm>
															</div>
														</div>
													);
												}}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		</div>
	);
};

export default ParticipationForm;
