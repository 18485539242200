import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { format_date } from "helpers/format";

export const darkenHexColor = (hexColor, factor = 0.5) => {
	// Remove the "#" symbol if present
	hexColor = hexColor.replace(/^#/, "");

	// Convert the hex color to RGB
	const r = parseInt(hexColor.substring(0, 2), 16);
	const g = parseInt(hexColor.substring(2, 4), 16);
	const b = parseInt(hexColor.substring(4, 6), 16);

	// Darken the color by reducing the brightness
	const darkR = Math.max(0, Math.floor(r * factor));
	const darkG = Math.max(0, Math.floor(g * factor));
	const darkB = Math.max(0, Math.floor(b * factor));

	// Convert the darkened RGB values back to hex
	const darkHexColor = `#${darkR.toString(16).padStart(2, "0")}${darkG
		.toString(16)
		.padStart(2, "0")}${darkB.toString(16).padStart(2, "0")}`;

	return darkHexColor;
};

const DashboardLastArticle = ({ article }) => {
	if (!article) return null;

	return (
		<Link
			to={`${process.env.REACT_APP_SITE_URL}/articles/${article.id}`}
			target="_blank"
			className="dashboard-last-article"
		>
			<div className="article-top">
				<div className="article-header">
					<div className="tag-container">
						{article.tags.map((tag, id) => (
							<div key={id} style={{ backgroundColor: tag.color }} className="tag">
								<span style={{ color: darkenHexColor(tag.color) }}>
									{tag.title}
								</span>
							</div>
						))}
					</div>
					<span> {format_date(article.created_at, "full_short")}</span>
				</div>
				<div className="article-content">
					<h3>{article.title}</h3>
					<p className="description">{article.description}</p>
				</div>
			</div>
			<div className="article-footer">
				<span className="read-btn">Lire le post</span>
				<span className="read-time">{article.reading_time} min</span>
			</div>
		</Link>
	);
};

export default DashboardLastArticle;
