// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-code-modal {
  width: 100%;
}
.promo-code-modal .promo-code-modal-top {
  display: flex;
  gap: 20px;
}
.promo-code-modal .checkbox-container {
  margin: 20px 0;
}
.promo-code-modal .multiple-select {
  width: 100%;
  max-width: 400px;
}
.promo-code-modal .multiple-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.promo-code-modal .multiple-wrapper .code-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.02);
  transition: 0.3s;
  width: 100%;
  gap: 20px;
}
.promo-code-modal .multiple-wrapper .code-form > * {
  width: calc(33% - 140px);
}`, "",{"version":3,"sources":["webpack://./src/components/events/create_update_event/tickets/promo_code/promo_code_form_modal/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,aAAA;EACA,SAAA;AACF;AAEC;EACC,cAAA;AAAF;AAGC;EACC,WAAA;EACA,gBAAA;AADF;AAIC;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;AAIE;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,gBAAA;EACA,WAAA;EACA,SAAA;AAFH;AAIG;EACC,wBAAA;AAFJ","sourcesContent":[".promo-code-modal {\n\twidth: 100%;\n\n\t.promo-code-modal-top {\n\t\tdisplay: flex;\n\t\tgap: 20px;\n\t}\n\n\t.checkbox-container {\n\t\tmargin: 20px 0;\n\t}\n\n\t.multiple-select {\n\t\twidth: 100%;\n\t\tmax-width: 400px;\n\t}\n\n\t.multiple-wrapper {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 10px;\n\n\t\t.code-form {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\talign-items: center;\n\t\t\tjustify-content: space-between;\n\t\t\tpadding: 20px;\n\t\t\tborder-radius: 15px;\n\t\t\tbackground-color: rgba($color: #000000, $alpha: 0.02);\n\t\t\ttransition: 0.3s;\n\t\t\twidth: 100%;\n\t\t\tgap: 20px;\n\n\t\t\t> * {\n\t\t\t\twidth: calc(33% - 140px);\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
