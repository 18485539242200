import { Button, Drawer, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";
import { getSpecificData } from "../../../helpers/store_data";
import main_logo from "assets/main/logo.png";
import dev_banner from "assets/main/dev-banner.png";
import DashboardIcon from "../../../assets/static/dashboard-icon-2.svg";
import EventlistIcon from "../../../assets/static/event-list-icon.svg";
import OrganisationIcon from "../../../assets/static/team-icon.svg";
import UsersOrganisationIcon from "../../../assets/static/users-organisation.svg";
import LogoutIcon from "../../../assets/static/logout-icon.svg";
import NewEvent from "../../../assets/navigation/tab.png";
import Website from "../../../assets/navigation/world-wide-web.png";

const NavbarItem = ({ pathname, icon, title }) => {
	const location = useLocation();
	return (
		<li className={location.pathname === pathname ? "active hov" : "hov"}>
			<Link to={pathname}>
				<div className="navigation_elem">
					<img src={icon} alt={title} />
					<p>{title}</p>
				</div>
			</Link>
		</li>
	);
};

const Navbar = () => {
	const location = useLocation();
	const { select_account, user, get_accounts, check_accounts, create_account, logout, take_down } =
		useContext(AuthContext);
	const [selectOpen, setSelectOpen] = useState(user.account.id === undefined);
	const [selectedAccount, setSelectedAccount] = useState("");
	const [createAccount, setCreateAccount] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);
	let new_account_name = useRef("");

	let all_accounts = get_accounts();

	useEffect(() => {
		setSelectOpen(user.account.id === undefined);
	}, [user]);

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setDrawerOpen(open);
	};

	const SelectAccount = () => {
		const navigate = useNavigate();

		useEffect(() => {
			const check = async () => {
				const check_account = await check_accounts();
				if (selectOpen && !check_account && user.first_name != "Anonymous") {
					navigate("/ask-demo");
					logout();
				}
			};
			if (selectOpen) {
				check();
			}
		}, [selectOpen]);

		const switch_account = () => {
			if (selectedAccount != "0") {
				select_account(selectedAccount);
				setSelectOpen(false);
			} else {
				if (!createAccount) {
					setCreateAccount(true);
				} else {
					create_account(new_account_name.current).then((e) => (all_accounts = get_accounts()));
					setCreateAccount(false);
					new_account_name.current = "";
					setSelectOpen(false);
				}
			}
		};

		if (!selectOpen) return null;
		return (
			<div className="select-account-view">
				<div className="select-account-center">
					<div className="select-account-header">
						<h3>Choisir une organisation</h3>
					</div>
					<div className="select-account-body">
						{createAccount ? (
							<div className="create-account">
								<p>Nom de votre organisation :</p>
								<TextField
									defaultValue={new_account_name.current}
									onChange={(e) => (new_account_name.current = e.target.value)}
									fullWidth
									className="value-input"
									id="outlined-basic"
									label={""}
									variant="outlined"
								/>
							</div>
						) : (
							<>
								{all_accounts.map((elem, id) => (
									<div
										onClick={() => setSelectedAccount(elem.organizer)}
										key={id}
										className={`account-elem${
											selectedAccount === elem.organizer ? " selected" : ""
										}${elem.id === "0" ? " outlined" : ""}`}
									>
										<span>{elem.title}</span>
									</div>
								))}
								<div
									onClick={() => setSelectedAccount("0")}
									className={`account-elem outlined ${selectedAccount === "0" ? "selected" : ""}`}
								>
									<i className={`fas fa-plus`}></i>
									<span>Créer une nouvelle organisation</span>
								</div>
							</>
						)}
					</div>
					<div className="select-account-footer">
						<Button
							disabled={!user.account.id}
							onClick={() => (createAccount ? setCreateAccount(false) : setSelectOpen(false))}
							className="close-button"
						>
							Annuler
						</Button>
						<Button
							disabled={selectedAccount === ""}
							onClick={() => switch_account()}
							className="add-button"
						>
							{selectedAccount === "0" ? "Créer" : "Sélectionner"}
						</Button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<header>
				{process.env.REACT_APP_SITE_URL.includes("dev") && (
					<img className="dev-banner" src={dev_banner} alt="dev-banner" />
				)}

				<Link to={"/"} className="pass-pass-logo">
					<img src={main_logo} />
				</Link>
				<ul>
					<li onClick={() => setSelectOpen(true)} className="current-account">
						<i className="fa fa-caret-down"></i> <span>{user.account.title}</span>
					</li>
					<li
						className={`small ${
							location.pathname.includes("/settings") && !location.pathname.includes("profile")
								? "active hov"
								: "hov"
						}`}
					>
						<Link aria-label="Settings" to="/settings/navigation">
							<i className="fas fa-cog"></i>
						</Link>
					</li>
					{getSpecificData("old-token") ? (
						<li>
							<Button onClick={take_down} color="error" variant="outlined" size="small">
								Leave take over
							</Button>
						</li>
					) : null}
				</ul>
				<div className="drawer-button">
					<DragHandleIcon sx={{ fontSize: 32 }} onClick={toggleDrawer(true)} />
					<Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
						<div className="drawer-content">
							<div onClick={() => setSelectOpen(true)} className="current-account-block">
								<div className="current-account-picture"></div>
								<span>{user.account.title}</span>
							</div>
							<ul onClick={() => setDrawerOpen(false)}>
								<NavbarItem title={"Dashboard"} pathname={"/"} icon={DashboardIcon} />

								<li>
									<div className="separator">
										<h2 className="separator_title">événements</h2>
									</div>
								</li>
								<NavbarItem title={"Mes événements"} pathname={"/events"} icon={EventlistIcon} />
								{/* <NavbarItem
									title={"Créer un événement"}
									pathname={"/create-event"}
									icon={NewEvent}
								/> */}

								<li>
									<div className="separator">
										<h2 className="separator_title">Organisation</h2>
									</div>
								</li>

								<li
									className={`small ${
										location.pathname.includes("/account-settings") &&
										location.pathname !== "/account-settings/developers"
											? "active hov"
											: "hov"
									}`}
								>
									<Link to="/account-settings/navigation">
										<div className="navigation_elem">
											<img src={DashboardIcon} alt="Dashboard Icon" />
											<p>Account settings</p>
										</div>
									</Link>
								</li>
								<li>
									<div className="separator"></div>
								</li>
								<li className="hov">
									<Link target="_blank" to={process.env.REACT_APP_SITE_URL}>
										<div className="navigation_elem">
											<img src={Website} alt="Logout Icon" />
											<p>Site web</p>
										</div>
									</Link>
								</li>
								<li className={location.pathname === "/logout" ? "active hov" : "hov"}>
									<Link to="/" onClick={logout}>
										<div className="navigation_elem">
											<img src={LogoutIcon} alt="Logout Icon" />
											<p>Déconnexion</p>
										</div>
									</Link>
								</li>
							</ul>
						</div>
					</Drawer>
				</div>
			</header>
			<SelectAccount />
		</>
	);
};

export default Navbar;
