// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-block-container {
  display: flex;
  flex-direction: column;
  min-width: 280px;
}
.dashboard-block-container.small {
  width: calc(33% - 30px);
  max-width: 450px;
}
.dashboard-block-container.large {
  width: calc(67% - 20px);
  max-width: 950px;
}
@media screen and (max-width: 1100px) {
  .dashboard-block-container.large {
    width: 100%;
    max-width: unset;
  }
  .dashboard-block-container.small {
    width: 100%;
    max-width: 400px;
  }
}
.dashboard-block-container .dashboard-block-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}
.dashboard-block-container .dashboard-block-header .title {
  font-size: 1.2rem;
  font-weight: bold;
}
.dashboard-block-container .dashboard-block-header .block-main-link {
  text-decoration: none;
  color: var(--clr-text);
}
.dashboard-block-container .dashboard-block-header .block-main-link img {
  margin-left: 10px;
  transition: 0.2s;
}
.dashboard-block-container .dashboard-block-header .block-main-link:hover img {
  transform: translateX(10px);
}
.dashboard-block-container .dashboard-block-header .block-main-link * {
  color: var(--clr-strong-text);
}
.dashboard-block-container .dashboard-block-content {
  border-radius: 10px;
  padding: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.168);
  margin-top: 10px;
  min-height: 280px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboard-block/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,gBAAA;AACD;AACC;EACC,uBAAA;EACA,gBAAA;AACF;AAEC;EACC,uBAAA;EACA,gBAAA;AAAF;AAGC;EACC;IACC,WAAA;IACA,gBAAA;EADD;EAGA;IACC,WAAA;IACA,gBAAA;EADD;AACF;AAIC;EACC,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAFF;AAIE;EACC,iBAAA;EACA,iBAAA;AAFH;AAKE;EACC,qBAAA;EACA,sBAAA;AAHH;AAKG;EACC,iBAAA;EACA,gBAAA;AAHJ;AAOI;EACC,2BAAA;AALL;AASG;EACC,6BAAA;AAPJ;AAYC;EACC,mBAAA;EACA,aAAA;EACA,wCAAA;EACA,gBAAA;EACA,iBAAA;AAVF","sourcesContent":[".dashboard-block-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmin-width: 280px;\n\n\t&.small {\n\t\twidth: calc(33% - 30px);\n\t\tmax-width: 450px;\n\t}\n\n\t&.large {\n\t\twidth: calc(67% - 20px);\n\t\tmax-width: 950px;\n\t}\n\n\t@media screen and (max-width: 1100px) {\n\t\t&.large {\n\t\t\twidth: 100%;\n\t\t\tmax-width: unset;\n\t\t}\n\t\t&.small {\n\t\t\twidth: 100%;\n\t\t\tmax-width: 400px;\n\t\t}\n\t}\n\n\t.dashboard-block-header {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\twidth: 100%;\n\t\talign-items: center;\n\t\tjustify-content: space-between;\n\t\tpadding-right: 10px;\n\n\t\t.title {\n\t\t\tfont-size: 1.2rem;\n\t\t\tfont-weight: bold;\n\t\t}\n\n\t\t.block-main-link {\n\t\t\ttext-decoration: none;\n\t\t\tcolor: var(--clr-text);\n\n\t\t\timg {\n\t\t\t\tmargin-left: 10px;\n\t\t\t\ttransition: 0.2s;\n\t\t\t}\n\n\t\t\t&:hover {\n\t\t\t\timg {\n\t\t\t\t\ttransform: translateX(10px);\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t* {\n\t\t\t\tcolor: var(--clr-strong-text);\n\t\t\t}\n\t\t}\n\t}\n\n\t.dashboard-block-content {\n\t\tborder-radius: 10px;\n\t\tpadding: 20px;\n\t\tborder: 0.5px solid rgba(0, 0, 0, 0.168);\n\t\tmargin-top: 10px;\n\t\tmin-height: 280px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
