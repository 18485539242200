import { Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import { GoogleMap, useLoadScript, Marker, useGoogleMap } from "@react-google-maps/api";
import "./style.scss";
import { ReactComponent as PinIcon } from "../../../../../assets/dashboard/location-dot-solid.svg";
import { Button } from "antd";

const MapModal = ({ close, save, center, open }) => {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE,
		libraries: ["places"],
	});
	const [map, setMap] = useState(null);

	const onMapLoad = React.useCallback((map) => {
		setMap(map);
	}, []);

	if (loadError) {
		return <div>Error loading maps</div>;
	}

	if (!isLoaded) {
		return <div>Loading maps</div>;
	}

	if (!open) {
		return null;
	}

	return (
		<Modal style={{ display: "flex", alignItems: "center", justifyContent: "center" }} open={true}>
			<div className="map-modal-center">
				<span>Adresse précise</span>
				<p>Veuillez déplacer la carte pour placer le marqueur à l'adresse précise de l'événement.</p>
				<div className="map-container">
					<GoogleMap
						onLoad={onMapLoad}
						onDragStart={() => document.getElementById("map-modal-pin").classList.add("up")}
						onDragEnd={() => document.getElementById("map-modal-pin").classList.remove("up")}
						mapContainerStyle={{ width: "100%", height: "100%" }}
						zoom={19}
						center={center}
						options={{
							disableDefaultUI: true,
						}}
					/>
					<div id="map-modal-pin" className={`pin-item`}>
						<PinIcon />
						<div className="pin-shadow" />
					</div>
				</div>
				<div className="modal-footer">
					<Button onClick={close}>Annuler</Button>
					<Button onClick={() => save(map.center.lat(), map.center.lng())} className="main" type="primary">
						Sauvegarder l'adresse
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default MapModal;
