import React, { useContext } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import "styles/dashboard.scss";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import EmptyView from "components/main/empty_view/EmptyView";
import CellLink from "components/table_components/CellLink";
import CellDate from "components/table_components/CellDate";
import CellStatus from "components/table_components/CellStatus";
import CellTime from "components/table_components/CellTime";
import PassPassTable from "components/table/table/PassPassTable";
import { format_price } from "helpers/format";

const columns = [
	{
		width: 140,
		headerName: "Order ID",
		field: "str_id",
		renderCell: (e) => <CellLink label={e.value} v={e} to={`/events/${e.row.event}/orders/${e.row.id}`} />,
	},
	{
		width: 140,
		headerName: "Participant ID",
		field: "profile",
		renderCell: (e) => (
			<CellLink label={e.value} trunk={12} to={`/events/${e.row.event}/participants/${e.row.profile}`} />
		),
	},
	{
		width: 100,
		headerName: "Brut",
		field: "amount",
		renderCell: (e) => <>{format_price(e.value)}</>,
	},
	{
		width: 100,
		headerName: "Prénom",
		field: "first_name",
	},
	{
		width: 100,
		headerName: "Nom",
		field: "last_name",
	},
	{
		width: 120,
		headerName: "Statut",
		field: "status",
		renderCell: (e) => (
			<CellStatus
				status={e.value}
				label_list={{ paid: "Paid", pending: "Pending", unpaid: "Unpaid" }}
				color_list={{
					paid: "var(--clr-success)",
					pending: "var(--clr-divider)",
					unpaid: "var(--clr-error)",
				}}
				icon_list={{
					paid: "fas fa-check",
					pending: "fas fa-clock",
					unpaid: "fas fa-times",
				}}
			/>
		),
	},
	{
		width: 200,
		headerName: "Created at",
		field: "created_at",
		renderCell: (e) => <CellDate date={e.value} />,
	},
	{
		width: 200,
		headerName: "Checkout time",
		field: "checkout_time",
		renderCell: (e) => <CellTime time={e.value} />,
	},
];

const EventOrders = () => {
	const { event_id } = useParams();
	const navigate = useNavigate();
	const { event } = useContext(CurrentEventContext);

	if (!event?.participants?.length) {
		return (
			<DefaultView>
				<EmptyView
					height="calc(100vh - 120px)"
					title="No orders"
					description="On this page you will see the list of orders for your event."
				/>
			</DefaultView>
		);
	}

	return (
		<DefaultView>
			<div id="event-orders" className="event-content-padding">
				<PassPassTable
					columns={columns.map((column, index) => {
						column.order = index;
						return column;
					})}
					pageSizeOptions={[10, 20, 50, 100]}
					title="Commandes"
					data={event?.payments ? [...event.payments].sort((a, b) => b.id - a.id) : []}
				/>
			</div>
		</DefaultView>
	);
};

export default EventOrders;
