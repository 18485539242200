import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./screens/404/404";
import GeneralAccountSettings from "./screens/account_settings/general_settings/GeneralAccountSettings";
import AccountNavigation from "./screens/account_settings/account_navigation/AccountNavigation";
import AccountMembers from "./screens/account_settings/members/AccountMembers";
import GeneralSettings from "./screens/settings/general_settings/GeneralSettings";
import Profile from "./screens/settings/profile/Profile";
import SettingsNavigation from "./screens/settings/settings_navigation/SettingsNavigation";
import EventList from "./screens/events/event_list/EventList";
import EventDashboard from "./screens/events/event_dashboard/EventDashboard";
import EventMembers from "./screens/events/event_members/EventMembers";
import EventParticipants from "./screens/events/event_participants/EventParticipants";
import EventParticipant from "./screens/events/event_participant/EventParticipant";
import EventTicket from "./screens/events/event_ticket/EventTicket";
import EventEntries from "./screens/events/event_entries/EventEntries";
import Logout from "./screens/auth/logout/Logout";
import { EventCreationProvider } from "./contexts/EventCreationContext";
import PublicProfile from "./screens/account_settings/public_profile/PublicProfile";
import Dashboard from "screens/dashboard/Dashboard";
import EventOrders from "screens/events/event_orders/EventOrders";
import EventOrder from "screens/events/event_order/EventOrder";
import ParticipationForms from "screens/events/participation_forms/ParticipationForms";
import FinancialData from "screens/account_settings/financial_data/FinancialData";
import BigNumber from "screens/admin/big_number/BigNumber";
import NewFinancialData from "screens/account_settings/new_financial_data/NewFinancialData";
import RefreshStripeLink from "screens/account_settings/new_financial_data/refresh_stripe_link/RefreshStripeLink";

const Main = ({ user }) => {
	return (
		<>
			<Routes>
				<Route path="/" element={<Dashboard />} />
				<Route path="/create-event" element={<EventCreationProvider />} />
				<Route path="/update-event/:event_id" element={<EventCreationProvider />} />
				<Route path="/login" element={<Logout />} />

				<Route path="/events" element={<EventList />} />

				<Route path="/events/:event_id" element={<EventDashboard />} />
				<Route path="/events/:event_id/participation-form" element={<ParticipationForms />} />
				<Route path="/events/:event_id/members" element={<EventMembers />} />
				<Route path="/events/:event_id/participants" element={<EventParticipants />} />
				<Route path="/events/:event_id/entries" element={<EventEntries />} />
				<Route path="/events/:event_id/orders" element={<EventOrders />} />
				<Route path="/events/:event_id/participants/:participant_id" element={<EventParticipant />} />
				<Route path="/events/:event_id/orders/:order_id" element={<EventOrder />} />
				<Route path="/events/:event_id/tickets/:ticket_id" element={<EventTicket />} />

				<Route path="/account-settings" element={<GeneralAccountSettings />} />
				<Route path="/organization-profile" element={<PublicProfile />} />

				<Route path="/account-settings/navigation" element={<AccountNavigation />} />
				<Route path="/account-settings/members" element={<AccountMembers />} />
				<Route path="/account-settings/finance" element={<NewFinancialData />} />
				<Route path="/account-settings/finance/refresh" element={<RefreshStripeLink />} />

				<Route path="/settings" element={<GeneralSettings />} />
				<Route path="/settings/profile" element={<Profile />} />
				<Route path="/settings/navigation" element={<SettingsNavigation />} />

				<Route path="/admin/big-number/:key" element={<BigNumber />} />

				<Route path="/*" element={<NotFound />} />
			</Routes>
		</>
	);
};

export default Main;
