import React, { useContext, useEffect, useState } from "react";
import "./login.scss";
import AuthContext from "../../../contexts/AuthContext";
import logo from "../../../assets/icon.png";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { App } from "antd";

function LogInForm() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const { user, login, login_one_tap, logout } = useContext(AuthContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { notification } = App.useApp();

	const handleSubmit = async () => {
		setLoading(true);
		const response = await login(email, password);
		if (!response) {
			notification.error({
				message: "Login failed",
				description: "Bad email or password",
				placement: "bottomLeft",
			});
		}
		navigate("/");
		setLoading(false);
	};

	const loginOneTap = async () => {
		// await logout();
		setLoading(true);
		await login_one_tap(searchParams.get("user"), searchParams.get("code"));
		navigate("/");
		setLoading(false);
	};

	useEffect(() => {
		if (searchParams.get("code")) {
			loginOneTap();
		} else if (!searchParams.get("tpetit")) {
			window.location.assign("https://passpass.be");
		}
	}, []);

	const validateEntry = () => {
		if (password === "" || email === "") return false;
		return true;
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && validateEntry()) {
			handleSubmit();
		}
	};

	if (searchParams.get("code")) {
		return null;
	}

	return (
		<section className="login">
			<div className="login-container">
				<div className="center_div">
					<div className="logo-title">
						<img alt="PassPass icon" src={logo} />
						<span>PassPass</span>
					</div>
					<h2>Sign in</h2>
					<form className="form" onSubmit={() => console.log("first")}>
						<TextField
							size="small"
							label="Email"
							type={"email"}
							autoComplete={"email"}
							fullWidth
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							variant="outlined"
						/>
						<TextField
							onKeyDown={handleKeyDown}
							size="small"
							label="Password"
							type={"password"}
							autoComplete={"password"}
							fullWidth
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							variant="outlined"
						/>
					</form>
					<LoadingButton
						loading={loading}
						variant="contained"
						disabled={!validateEntry()}
						onClick={() => {
							handleSubmit();
						}}
						fullWidth
					>
						Sign in
					</LoadingButton>
					<span className="other-method-link">
						You don't have any account ?{" "}
						<Link to={`${process.env.REACT_APP_SITE_URL}/auth/signin`}>Sign up</Link>
					</span>
				</div>
			</div>
		</section>
	);
}

function Login() {
	const { user } = useContext(AuthContext);

	if (user.token) {
		return redirect("/");
	} else {
		return <LogInForm />;
	}
}

export default Login;
