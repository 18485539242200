import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import ArrowIcon from "assets/static/light-arrow-icon.svg";
import AuthContext from "contexts/AuthContext";
import DashboardBlock from "components/dashboard/dashboard-block/DashboardBlock";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "store/dispatcher";
import DashboardNextEvent from "components/dashboard/dashboard-next-event/DashboardNextEvent";
import axios from "../../services/axios";
import DashboardLastArticle from "components/dashboard/dashboard-last-article/DashboardLastArticle";

const CardItem = ({ pathname, title, paragraph, icon, button }) => {
	const location = useLocation();
	return (
		<Link className="card-link" to={pathname}>
			<div className="card">
				<div className="card-content">
					<h2>{title}</h2>
					<p>{paragraph}</p>
				</div>

				<div className="card-button">
					<span>{button}</span>
					<img src={icon} alt={title} />
				</div>
			</div>
		</Link>
	);
};

const Dashboard = ({ events }) => {
	const { user } = useContext(AuthContext);
	const [lastArticle, setLastArticle] = useState(null);

	const now = new Date().getTime();
	const events_sorted = [...events]
		.filter((e) => e.start_at_time > now)
		.sort((a, b) => a.start_at_time - b.start_at_time);

	const load_last_article = async () => {
		const ret = await axios
			.get(`/api/articles`)
			.then((e) => e.data)
			.catch((e) => null);

		if (ret && ret.length) {
			setLastArticle(ret[ret.length - 1]);
		}
	};

	useEffect(() => {
		load_last_article();
	}, []);

	return (
		<div id="dashboard" className="full-width">
			<div className="intro-content event-content">
				<h1>Bonjour, {user.first_name}</h1>
				<p>Que souhaitez-vous faire aujourd'hui ?</p>

				<div className="cards-container">
					<CardItem
						title={"Publier un événement"}
						pathname={"/create-event"}
						paragraph={
							"Organisez votre événement, ajoutez les détails, créez des billets, des suppléments et partagez le."
						}
						icon={ArrowIcon}
						button={"Créer un événement"}
					/>

					<CardItem
						title={"Comment pouvons-nous vous aider ?"}
						pathname={"mailto:contact@passpass.be"}
						paragraph={
							"Notre équipe est là pour vous guider dans la création de vos événements et répondre à toutes vos questions."
						}
						icon={ArrowIcon}
						button={"Prendre contact"}
					/>

					<CardItem
						title={"Participer à l'amélioration de PassPass !"}
						pathname={"mailto:contact@passpass.be"}
						paragraph={
							"La plateforme est actuellement en phase bêta, et nous voulons entendre vos retours ! "
						}
						icon={ArrowIcon}
						button={"Témoigner"}
					/>
				</div>

				<div className="next-event-container">
					<div className="cards-container">
						<DashboardBlock
							width={2}
							title={"Prochain événement"}
							link_label={"Tous mes événements"}
							link_href={"/events"}
							type="dark"
						>
							<DashboardNextEvent event={events_sorted.length ? events_sorted[0] : null} />
						</DashboardBlock>
						<DashboardBlock
							title={"Dernier article"}
							link_label={"Tous les articles"}
							link_href={"https://passpass.be/articles"}
						>
							<DashboardLastArticle article={lastArticle} />
						</DashboardBlock>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
