import { format_date, format_time } from "./format";

export const entries_refactor = (entries) => {
	const ret_entries = [];
	for (let index = 0; index < entries.length; index++) {
		const entry = entries[index];
		const validated_at = new Date(entry.validated_at);
		ret_entries.push({
			...entry,
			v_at: validated_at,
			str_id: entry.id.toString().padStart(8, "0"),
			ticket_str_id: "TIC-" + entry.ticket.toString().padStart(8, "0"),
		});
	}

	return ret_entries;
};

export const participants_refactor = (participants, event_id, event) => {
	const ret_participants = [];
	for (let index = 0; index < participants.length; index++) {
		const participant = participants[index];

		ret_participants.push({
			...participant,
			str_id: "TIC-" + participant.id.toString().padStart(8, "0"),
			full_name: `${participant.first_name} ${participant.last_name}`,
			event: event_id,
			created_date: participant.created_at.substring(0, 10),
			slot: participant.event_date
				? event.dates.find((date) => date.id === participant.event_date).start_at
				: null,
		});
	}

	return ret_participants;
};

export const orders_refactof = (orders) => {
	const ret_orders = [];
	for (let index = 0; index < orders.length; index++) {
		const order = orders[index];

		ret_orders.push({
			...order,
			checkout_time: order.paid ? (new Date(order.paid_at) - new Date(order.created_at)) / 1000 : 0,
			str_id: "ORD-" + order.id.toString().padStart(8, "0"),
			status: order.paid
				? "paid"
				: new Date().getTime() > new Date(order.payable_until).getTime()
				? "unpaid"
				: "pending",
		});
	}

	return ret_orders;
};
