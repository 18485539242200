import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import { getData, getSpecificData, storeData } from "../helpers/store_data";
import axios, { set_instance_token, unset_instance_token } from "../services/axios";

const AuthContext = createContext();

const default_user = {
	first_name: "Anonymous",
	last_name: "User",
	token: null,
	account: {},
	need_reload: true,
};

export const AuthProvider = (props) => {
	const [user, setUser] = useState({ ...default_user });
	const [refresh, setRefresh] = useState(false);
	const accounts = useRef(null);

	const fetch_accounts = async (id) => {
		const need = await axios
			.get("/api/me/organizers")
			.then((response) => {
				accounts.current = response.data;
				setRefresh((refresh) => !refresh);
				return true;
			})
			.catch((e) => {
				if (e.response?.status === 401) {
					logout();
				}
				accounts.current = [];
				return false;
			});
		if (id && need) {
			const ret = await axios
				.get(`/api/organizers/${id}`)
				.then((response) => {
					return response.data;
				})
				.catch((e) => null);
			if (ret) {
				return ret;
			}
		}

		return null;
	};

	const fetch_account = async (id) => {
		const ret = await axios
			.get(`/api/organizers/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				console.log(e);
				return null;
			});
		if (ret) {
			if (user.account.id === ret.id) setUser({ ...user, account: ret });
			else setUser({ ...user });
		}
		return ret;
	};

	const select_account = async (organizer) => {
		accounts.current.map(async (elem) => {
			if (elem.organizer === organizer) {
				const account = await axios
					.get(`/api/organizers/${organizer}`)
					.then((response) => {
						return response.data;
					})
					.catch((e) => {
						console.log(e);
						return null;
					});
				if (account) {
					storeData("account", account);
					setUser({ ...user, need_reload: true, account: account });
				}
			}
		});
	};

	const update_account = async (updated_account) => {
		delete updated_account.id;
		const account = getData();
		accounts.current.map((e) => {
			if (e.id === account.account.id) {
				e.title = updated_account.title;
			}
		});
		storeData("account", {
			...account.account,
			...update_account,
		});
		setUser({
			...user,
			account: { ...account.account, ...updated_account },
			need_reload: true,
		});
	};

	const get_accounts = () => {
		return accounts.current;
	};

	const check_accounts = async () => {
		const ret = await axios
			.get("/api/me/organizers")
			.then((e) => e.data)
			.catch((e) => null);
		if (ret && ret.length) {
			return true;
		}
		return false;
	};

	const profile = async () => {
		const ret = await axios
			.get("/api/me")
			.then((e) => e.data)
			.catch((e) => null);
		return ret;
	};

	const create_account = async (name) => {
		await axios
			.post("/api/organizers", {
				title: name,
				description: "coucou",
				is_private: false,
				address_label: "Lln",
				address_latitude: 50,
				address_longitude: 50,
				main_picture: "",
				cover_picture: "",
				page_url: "",
			})
			.then((response) => {
				accounts.current.push(response.data);
				storeData("account", response.data);
				setUser({ ...user, need_reload: true, account: response.data });
			})
			.catch((e) => {
				console.log(e);
				accounts.current = [];
			});
	};

	const login = async (email, password) => {
		const usr = await axios
			.post("/auth/login", {
				email: email,
				password: password,
			})
			.then(async (response) => {
				const token = response.data;
				set_instance_token(token);
				storeData("token", token);
				fetch_accounts();
				const user_data = await profile();
				setUser({
					...user,
					token: token,
					need_reload: true,
					account: {},
					...user_data,
				});
				return {
					...user,
					token: token,
					need_reload: true,
					account: {},
					...user_data,
				};
			})
			.catch((e) => {
				console.log(e);
				return null;
			});
		return usr;
	};

	const login_one_tap = async (user_id, one_time) => {
		const usr = await axios
			.post(`/auth/one-time-login/${user_id}`, {
				email: one_time,
				password: "",
			})
			.then(async (response) => {
				const token = response.data;
				set_instance_token(token);
				storeData("token", token);
				fetch_accounts();
				const user_data = await profile();
				setUser({
					...user,
					token: token,
					need_reload: true,
					account: {},
					...user_data,
				});
				return {
					...user,
					token: token,
					need_reload: true,
					account: {},
					...user_data,
				};
			})
			.catch((e) => {
				console.log(e);
				return null;
			});
		return usr;
	};

	const setup = async () => {
		const app_data = getData();
		if (app_data.token) {
			set_instance_token(app_data.token);
			const me = await profile();
			if (!me) {
				if (getSpecificData("old-token")) {
					unset_instance_token();
					const theme = localStorage.getItem("theme");
					const current_account = localStorage.getItem("old-account");
					const token = getSpecificData("old-token");
					localStorage.clear();
					localStorage.setItem("theme", theme);
					localStorage.setItem("account", current_account);
					storeData("token", token);
					setup();
				} else {
					logout();
				}
				return;
			}
			const usr = {
				...user,
				token: app_data.token,
				need_reload: true,
				...me,
			};

			if (app_data.account) {
				usr.account = app_data.account;
			}
			const account = await fetch_accounts(usr.account?.id);
			if (account) {
				usr.account = account;
				setUser(usr);
			} else {
				setUser(usr);
			}
		} else {
			accounts.current = [];
			setUser({
				first_name: "Anonymous",
				last_name: "User",
				token: "",
				account: {},
				need_reload: true,
			});
		}
	};

	const take_down = async () => {
		const theme = localStorage.getItem("theme");
		const current_account = localStorage.getItem("old-account");
		const token = getSpecificData("old-token");
		localStorage.clear();
		localStorage.setItem("theme", theme);
		localStorage.setItem("account", current_account);
		storeData("token", token);
		set_instance_token(token);
		const prof = await profile();
		if (prof) {
			fetch_accounts();
			setUser({
				...user,
				token: token,
				need_reload: true,
				account: getSpecificData("account"),
			});
		}
	};

	const toggle_theme = (theme) => {
		setUser({ ...user, theme: theme, need_reload: false });
	};

	useEffect(() => {
		setup();
	}, []);

	const verify_email = async (email, password) => {};

	const register = async () => {};

	const logout = async (data) => {
		setUser({
			first_name: "Anonymous",
			last_name: "User",
			token: "",
			account_id: "",
		});
		accounts.current = [];
		const theme = localStorage.getItem("theme");
		localStorage.clear();
		localStorage.setItem("theme", theme);
		unset_instance_token();
	};

	if (user.token === null) return null;
	if (accounts.current === null && user.token) return null;

	return (
		<AuthContext.Provider
			value={{
				user,
				accounts,
				login,
				login_one_tap,
				verify_email,
				register,
				logout,
				select_account,
				get_accounts,
				check_accounts,
				create_account,
				toggle_theme,
				update_account,
				setup,
				fetch_account,
				take_down,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
