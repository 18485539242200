import { Button } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

const DefaultView = ({ children, visible = "", save, reset, is_valid }) => {
	const location = useLocation();

	return (
		<div id="settings-base-view">
			<ul className={`account-navigation ${visible === "always" ? "full" : "aside"}`}>
				<li className={location.pathname === "/settings" ? "active hov" : "hov"}>
					<Link to="/settings">
						<div className="account-nav-elem">
							<i className="fas fa-cog"></i>
							<div>
								<span>Paramètres généraux</span>
{/* 								<p>Dashboard language, dashboad aparence...</p>
 */}							</div>
						</div>
					</Link>
				</li>
				<li className={location.pathname === "/settings/profile" ? "active hov" : "hov"}>
					<Link to="/settings/profile">
						<div className="account-nav-elem">
							<i className="fas fa-user"></i>
							<div>
								<span>Profil</span>
								<p>Profile details, linked accounts, sessions...</p>
							</div>
						</div>
					</Link>
				</li>
			</ul>
			{!save ? (
				<>{children}</>
			) : (
				<>
					{children}
					<div className="save-changes">
						<Button onClick={reset} variant="text">
							reset
						</Button>
						<Button onClick={save} disabled={!is_valid()} variant="contained">
							Save
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default DefaultView;
