import DefaultView from "components/events/default_view/DefaultView";
import React, { useEffect, useState } from "react";
import "./style.scss";
import axios from "../../../services/axios";
import { useParams } from "react-router-dom";
import CreateUpdateFormModal from "components/events/participation_forms/create_update_form_modal/CreateUpdateFormModal";

const ParticipationForms = () => {
	const [forms, setForms] = useState([]);
	const { event_id } = useParams();
	const [selected, setSelected] = useState(null);

	const load_forms = async () => {
		const form_data = await axios
			.get(`/api/events/${event_id}/participation-form`)
			.then((e) => e.data)
			.catch(() => []);
		form_data.sort((a, b) => a.id - b.id);
		setForms(form_data);
	};

	const create_form = async (data) => {
		data.questions = JSON.stringify(data.questions);
		data.event = parseInt(event_id);
		const new_form = await axios
			.post(`/api/events/${event_id}/participation-form`, data)
			.then((e) => e.data)
			.catch(() => []);
		setSelected(null);
		load_forms();
	};

	const update_form = async (data) => {
		data.questions = JSON.stringify(data.questions);
		data.event = parseInt(event_id);
		const form_id = data.id;
		delete data.id;
		const updated_form = await axios
			.put(`/api/events/${event_id}/participation-form/${form_id}`, data)
			.then((e) => e.data)
			.catch(() => []);
		setSelected(null);
		load_forms();
	};

	useEffect(() => {
		load_forms();
	}, []);

	return (
		<DefaultView>
			<CreateUpdateFormModal
				close={() => setSelected(null)}
				create={create_form}
				update={update_form}
				forms={forms}
				selected={selected}
			/>
			<div className="participation-forms">
				<div className="participation-header">
					<h1>Formulaire de commande</h1>
					<p>
						Ces formulaires peuvent être liés à vos differents tickets et vous
						permettent ainsi de récupérer des informations précieuses de la part de vos
						participants.
					</p>
				</div>
				<div className="form-container">
					{forms.map((form, index) => (
						<div className="form-item" key={index} onClick={() => setSelected(form.id)}>
							<span className="form-number">Formulaire n°{index + 1}</span>
							<span className="title">{form.title}</span>
						</div>
					))}
					<div className="form-item new" onClick={() => setSelected("new")}>
						<span>Nouveau formulaire</span>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default ParticipationForms;
