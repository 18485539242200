import React from "react";
import "styles/dashboard.scss";

function formatTime(seconds) {
	if (seconds == -1) {
		return "";
	}
	if (seconds < 60) {
		// less than 1 minute
		return seconds + " sec";
	} else if (seconds < 3600) {
		// less than 1 hour
		var minutes = Math.floor(seconds / 60);
		var sec = seconds % 60;
		return minutes + " min " + parseInt(sec) + " sec";
	} else {
		// 1 hour or more
		var hours = Math.floor(seconds / 3600);
		var minutes = Math.floor((seconds % 3600) / 60);
		var sec = seconds % 60;
		return hours + " h " + minutes + " min " + parseInt(sec) + " sec";
	}
}

const CellTime = ({ time }) => {
	if (!time) {
		return <span>---</span>;
	}
	return <span>{formatTime(time)}</span>;
};

export default CellTime;
