// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-container-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.label-container-item.disabled {
  opacity: 0.4;
}
.label-container-item label {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/main/label_content/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,WAAA;AACD;AACC;EACC,YAAA;AACF;AAEC;EACC,kBAAA;AAAF","sourcesContent":[".label-container-item {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: flex-start;\n\tjustify-content: flex-start;\n\twidth: 100%;\n\n\t&.disabled {\n\t\topacity: 0.4;\n\t}\n\n\tlabel {\n\t\tmargin-bottom: 5px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
