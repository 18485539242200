import React from "react";
import "./style.scss";
import CheckIcon from "@mui/icons-material/Check";

const TableContent = ({ data, columns, selection = [], select = () => null }) => {
	const selected_count = data.filter((e) => selection.includes(e.passpass_table_id)).length;

	return (
		<div className="passpass-table-content keep-bar">
			<table>
				<thead>
					<tr>
						<td className="column-header">
							<button
								onClick={() => select("all", selected_count, data.length)}
								className={`checkbox ${
									selected_count == data.length && data.length > 0
										? "selected"
										: selected_count != 0
										? "part-selected"
										: ""
								}`}
								role="checkbox"
							>
								<CheckIcon fontSize="12" />
							</button>
						</td>
						{columns.map((column, id) => (
							<td key={id}>
								<div className="column-header">{column.headerName}</div>
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((line, id) => (
						<tr key={id} className={selection.includes(line.passpass_table_id) ? "selected" : ""}>
							<td onClick={() => select(line.passpass_table_id)} className="table-item">
								<button
									className={`checkbox ${
										selection.includes(line.passpass_table_id) ? "selected" : ""
									}`}
								>
									<CheckIcon fontSize="12" />
								</button>
							</td>
							{columns.map((column, c_id) => (
								<td key={c_id}>
									{column.renderCell != null ? (
										column.renderCell({
											value: line[column.field],
											row: line,
										})
									) : (
										<div className="table-item">{line[column.field]}</div>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TableContent;
