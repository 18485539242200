import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "./screens/404/404";
import Login from "./screens/auth/login/Login";
import Demo from "screens/auth/demo/Demo";

const Log = () => {
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/ask-demo" element={<Demo />} />
			<Route path="*" element={<Navigate to="/login" />} />
		</Routes>
	);
};

export default Log;
