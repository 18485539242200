import React, { useContext, useEffect, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import EmptyView from "components/main/empty_view/EmptyView";
import CellLink from "components/table_components/CellLink";
import CellDate from "components/table_components/CellDate";
import PassPassTable from "components/table/table/PassPassTable";
import { format_date, format_price, format_time } from "helpers/format";

const columns = [
	{
		width: 140,
		headerName: "Ticket ID",
		field: "str_id",
		renderCell: (e) => <CellLink label={e.value} v={e} to={`/events/${e.row.event}/tickets/${e.row.id}`} />,
		hidden: true,
	},
	{
		width: 140,
		headerName: "Participant ID",
		field: "profile",
		renderCell: (e) => <CellLink label={e.value} to={`/events/${e.row.event}/participants/${e.row.profile}`} />,
		hidden: true,
	},
	{
		width: 100,
		headerName: "Prénom",
		field: "first_name",
		renderCell: (e) => <CellLink label={e.value} to={`/events/${e.row.event}/participants/${e.row.profile}`} />,
	},
	{
		width: 100,
		headerName: "Nom",
		field: "last_name",
		renderCell: (e) => <CellLink label={e.value} to={`/events/${e.row.event}/participants/${e.row.profile}`} />,
	},
	{
		width: 100,
		headerName: "Email",
		field: "email",
	},
	{
		width: 100,
		headerName: "Nom du ticket",
		field: "ticket_title",
	},
	{
		width: 100,
		headerName: "Code promo",
		field: "promo_code_key",
		hidden: true,
	},
	{
		width: 100,
		headerName: "Prix d'achat",
		field: "price",
		renderCell: (e) => <span>{format_price(e.value)}</span>,
		exportCell: (e) => parseFloat((e / 101).toFixed(2)),
	},

	{
		width: 200,
		headerName: "Dernière validation",
		field: "last_validation",
		renderCell: (e) => <CellDate date={e.value} />,
		hidden: true,
	},
	{
		width: 200,
		headerName: "Date d'achat",
		field: "created_at",
		renderCell: (e) => <CellDate date={e.value} />,
		hidden: true,
	},
	{
		width: 200,
		headerName: "Créneau horaire réservé",
		field: "slot",
		renderCell: (e) => <CellDate date={e.value} />,
		exportCell: (e) => {
			const real_date = new Date(e);
			return `${format_date(real_date, "full_short")} | ${format_time(real_date)}`;
		},
	},
];

const EventParticipants = () => {
	const { event_id } = useParams();
	const navigate = useNavigate();
	const { event, load_participants } = useContext(CurrentEventContext);

	useEffect(() => {
		if (event?.id) {
			load_participants(parseInt(event_id));
		}
	}, [event]);

	if (!event?.participants?.length) {
		return (
			<DefaultView>
				<EmptyView
					height="calc(100vh - 120px)"
					title="No participants"
					description="We don't have any participants for your event yet. Don't hesitate to share the link to this event."
				/>
			</DefaultView>
		);
	}

	console.log(event.participants);

	return (
		<DefaultView>
			<div id="event-participants">
				<div className="export-form">
					<span className="">Télécharger les données des formulaires</span>
				</div>
				<PassPassTable
					columns={columns.map((column, index) => {
						column.order = index;
						return column;
					})}
					pageSizeOptions={[10, 20, 50, 100]}
					title="Participants"
					data={event?.participants ? [...event.participants].sort((a, b) => b.id - a.id) : []}
				/>
			</div>
		</DefaultView>
	);
};

export default EventParticipants;
