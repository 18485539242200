import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import AuthContext from "../../../contexts/AuthContext";
import { Autocomplete, Button, Chip, MenuItem, Select, TextField } from "@mui/material";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "../../../services/axios";
import { TOAST_LVL } from "../../../constants/constants";
import NewMember from "../../../components/account_settings/members/new_member/NewMember";
import addIcon from "../../../../src/assets/static/add-icon.svg";
import { App } from "antd";

const AccountMembers = () => {
	const { user } = useContext(AuthContext);
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const [newUser, setNewUser] = useState(false);
	const [members, setMembers] = useState([]);
	const account = useRef(null);
	const { notification } = App.useApp();

	const load_settings = async () => {
		const response = await axios
			.get(`/api/organizers/${user.account.id}/accesses`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			account.current = response;
			setMembers(JSON.parse(JSON.stringify(response)));
		}
	};

	useEffect(() => {
		load_settings();
	}, []);

	const delete_user = async (delete_id, name) => {
		const ret = await axios
			.delete(`/api/organizers/${user.account.id}/accesses/${delete_id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			notification.success({
				message: "Membre supprimé",
				description: `The access rights on ${user.account.name} have been updated`,
				placement: "bottomLeft",
			});
		} else {
			notification.error({
				message: "Membre non supprimé",
				description: "You do not have sufficient rights to perform this action",
				placement: "bottomLeft",
			});
		}

		load_settings();
	};

	const save = async () => {
		const to_update = [];
		for (let index = 0; index < members.length; index++) {
			const member = members[index];
			const equivalent = account.current.filter((e) => e.id === member.id);
			if (equivalent.length && equivalent[0].role != member.role) {
				to_update.push(member);
			}
		}

		let error = false;

		for (let index = 0; index < to_update.length; index++) {
			const element = to_update[index];
			const ret = await axios
				.put(`/api/organizers/${user.account.id}/accesses`, {
					role: element.role,
					profile: element.profile,
				})
				.then((e) => e.data)
				.catch((e) => {
					error = true;
					return null;
				});
		}

		if (!error) {
			notification.success({
				message: "Access updated",
				description: `The access rights on ${user.account.name} have been updated`,
				placement: "bottomLeft",
			});
		} else {
			notification.error({
				message: "Access not updated",
				description: `You don't have the correct rights for this action`,
				placement: "bottomLeft",
			});
		}

		load_settings();
	};

	const reset = () => {
		setMembers(JSON.parse(JSON.stringify(account.current)));
	};

	const valid = () => {
		return members.length && JSON.stringify(members) !== JSON.stringify(account.current);
	};

	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>Membres de votre organisation {/* {user.account.name} */}</h1>
						<p>
							En tant qu'administrateur, vous pouvez ajouter des membres à votre organisation et gérer
							leurs droits d'accès.
						</p>
					</div>
					<div className="setting-block">
						<div className="block-container full-width">
							<div className="setting-block-description">
								<h2>Liste des membres</h2>
								<p>Les utilisateurs de cette liste font partie de votre organisation</p>
							</div>
							<div className="setting-block-content" id="account-members">
								<div className="table-container-users">
									<table>
										<thead>
											<tr>
												<th align="start">Utilisateur</th>
												<th align="start">Rôle</th>
												<th className="delete">
													<Button
														onClick={() => setNewUser(true)}
														className="create-event-btn"
													>
														<img src={addIcon} alt="" />
														<span>Ajouter un membre </span>
													</Button>
												</th>
											</tr>
										</thead>
										<tbody>
											{members.map((elem, id) => (
												<tr key={id}>
													<td>
														<div className="user-name-container">
															<p>
																{elem.first_name} {elem.last_name}{" "}
																{elem.profile === user.id ? "(you)" : ""}
															</p>
															{elem.id === user.account.owner ? (
																<span className="is_admin">Administrator</span>
															) : null}
														</div>
													</td>
													<td>
														<Autocomplete
															multiple
															id="tags-standard"
															options={[
																"admin",
																"team_manager",
																"event_manager",
																"organization_manager",
															]}
															getOptionLabel={(option) => {
																return {
																	admin: "Administrateur",
																	team_manager: "Gestionnaire d'équipe",
																	event_manager: "Gestionnaire d'événement",
																	organization_manager:
																		"Gestionnaire de l'organisation",
																}[option];
															}}
															renderTags={(tagValue, getTagProps) =>
																tagValue.map((option, index) => (
																	<Chip
																		label={option}
																		{...getTagProps({ index })}
																		disabled={option == "owner"}
																	/>
																))
															}
															onChange={(event, value) => {
																const members_copy = [...members];
																members_copy[id].role = value.join("|");

																setMembers(members_copy);
															}}
															value={
																elem.role === ""
																	? []
																	: elem.role
																			.trim()
																			.split("|")
																			.map((elem) => {
																				return {
																					owner: "Propriétaire",
																					admin: "Administrateur",
																					team_manager:
																						"Gestionnaire d'équipe",
																					event_manager:
																						"Gestionnaire d'événement",
																					organization_manager:
																						"Gestionnaire de l'organisation",
																				}[elem];
																			})
															}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant="outlined"
																	placeholder="Roles"
																/>
															)}
														/>
													</td>
													{elem.id === user.account.owner ? (
														<td></td>
													) : (
														<td
															onClick={() =>
																open_confirm(
																	`Supprimer l'accès à "${elem.first_name} ${elem.last_name}"`,
																	"Il s'agit d'une action destructrice. Tous les droits de cet utilisateur seront supprimés.																	",
																	`Supprimer`,
																	() =>
																		delete_user(
																			elem.profile,
																			`${elem.last_name} ${elem.first_name}`
																		)
																)
															}
															className="delete"
															align="end"
														>
															<DeleteOutlineIcon />
														</td>
													)}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NewMember add_member={load_settings} close={() => setNewUser(false)} active={newUser} />
		</DefaultView>
	);
};

export default AccountMembers;
