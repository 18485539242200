// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-update-form-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-update-form-modal .create-update-form-center {
  width: 700px;
  max-width: 90%;
  padding: 20px;
  background-color: var(--clr-background);
  border-radius: var(--border-radius);
}
.create-update-form-modal .create-update-form-center .create-update-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.create-update-form-modal .create-update-form-center .create-update-content {
  margin: 20px 0;
}
.create-update-form-modal .create-update-form-center .create-update-content .input-title-form {
  margin-bottom: 10px;
}
.create-update-form-modal .create-update-form-center .create-update-content .questions-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.create-update-form-modal .create-update-form-center .create-update-content .questions-container .question-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.create-update-form-modal .create-update-form-center .create-update-content .questions-container .question-item .question-form {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.create-update-form-modal .create-update-form-center .create-update-content .questions-container .question-item .question-form .ant-select {
  min-width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/components/events/participation_forms/create_update_form_modal/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,YAAA;EACA,cAAA;EACA,aAAA;EACA,uCAAA;EACA,mCAAA;AACF;AACE;EACC,aAAA;EACA,yBAAA;EACA,SAAA;AACH;AAEE;EACC,cAAA;AAAH;AAEG;EACC,mBAAA;AAAJ;AAGG;EACC,aAAA;EACA,sBAAA;EACA,iBAAA;AADJ;AAGI;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AADL;AAGK;EACC,aAAA;EACA,mBAAA;EACA,SAAA;AADN;AAGM;EACC,gBAAA;AADP","sourcesContent":[".create-update-form-modal {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\t.create-update-form-center {\n\t\twidth: 700px;\n\t\tmax-width: 90%;\n\t\tpadding: 20px;\n\t\tbackground-color: var(--clr-background);\n\t\tborder-radius: var(--border-radius);\n\n\t\t.create-update-footer {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: flex-end;\n\t\t\tgap: 20px;\n\t\t}\n\n\t\t.create-update-content {\n\t\t\tmargin: 20px 0;\n\n\t\t\t.input-title-form {\n\t\t\t\tmargin-bottom: 10px;\n\t\t\t}\n\n\t\t\t.questions-container {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tpadding-top: 20px;\n\n\t\t\t\t.question-item {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tflex-direction: column;\n\t\t\t\t\tgap: 10px;\n\n\t\t\t\t\t.question-form {\n\t\t\t\t\t\tdisplay: flex;\n\t\t\t\t\t\tflex-direction: row;\n\t\t\t\t\t\tgap: 10px;\n\n\t\t\t\t\t\t.ant-select {\n\t\t\t\t\t\t\tmin-width: 120px;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
