import React, { useContext, useEffect, useRef, useState } from "react";
// import "./style.scss";
import AuthContext from "../../../contexts/AuthContext";
import EventCreationContext from "../../../contexts/EventCreationContext";
import { usePlacesWidget } from "react-google-autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MDEditor from "@uiw/react-md-editor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { generate_image_path } from "helpers/images";
import CreateUpdateAside from "components/events/create_update_event/create_update_aside/CreateUpdateAside";
import GeneralDataPreview from "components/events/create_update_event/previews/GeneralDataPreview";
import MapModal from "components/events/create_update_event/general_data/map_modal/MapModal";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import { event_date_format } from "helpers/format";

const GeneralData = () => {
	const { user } = useContext(AuthContext);
	const { event, update_event, base_data } = useContext(EventCreationContext);
	const [reload, setReload] = useState(false);
	const [openMap, setOpenMap] = useState(false);
	const navigate = useNavigate();
	const formData = useRef({
		title: event.title,
		organizer: event.organizer,
		small_description: event.small_description,
		description: event.description,
		start_at: event.start_at,
		end_at: event.end_at,
		address_label: event.address_label,
		address_name: event.address_name,
		address_latitude: event.address_latitude,
		address_longitude: event.address_longitude,
		main_picture_64: null,
		gallery_64: [],
	});
	const [formDataState, setFormDataState] = useState({ ...formData.current });
	const mainImage = useRef(event.main_picture_64);
	const gallery = useRef(event.gallery_64);
	const can_save =
		JSON.stringify({
			title: base_data.current.title,
			organizer: base_data.current.organizer,
			small_description: base_data.current.small_description,
			description: base_data.current.description,
			start_at: base_data.current.start_at,
			end_at: base_data.current.end_at,
			address_label: base_data.current.address_label,
			address_name: base_data.current.address_name,
			address_latitude: base_data.current.address_latitude,
			address_longitude: base_data.current.address_longitude,
			main_picture_64: null,
			gallery_64: [],
		}) !=
			JSON.stringify({
				title: formDataState.title,
				organizer: formDataState.organizer,
				small_description: formDataState.small_description,
				description: formDataState.description,
				start_at: formDataState.start_at,
				end_at: formDataState.end_at,
				address_label: formDataState.address_label,
				address_name: formDataState.address_name,
				address_latitude: formDataState.address_latitude,
				address_longitude: formDataState.address_longitude,
				main_picture_64: formDataState.main_picture_64,
				gallery_64: formDataState.gallery_64,
			}) ||
		mainImage.current ||
		gallery.current?.length;

	const save_data = () => {
		formData.current.main_picture_64 = mainImage.current;
		formData.current.gallery_64 = gallery.current;
		update_event("general", { ...formData.current });
	};

	const clean = () => {
		mainImage.current = null;
		gallery.current = [];
		setReload(!reload);
	};

	const set_address = (latitude, longitude) => {
		formData.current.address_latitude = latitude;
		formData.current.address_longitude = longitude;
		setFormDataState(formData.current);
		setOpenMap(true);
	};

	const get_images = (input_id, is_main = false) => {
		const input = document.getElementById(input_id);
		const images = [];
		let end_count = 0;

		for (let index = 0; index < input.files.length; index++) {
			const reader = new FileReader();
			reader.onload = function (event) {
				images.push(event.target.result);
				end_count += 1;
				if (end_count === input.files.length) {
					if (is_main && images.length) {
						mainImage.current = [input.files[index], images[0]];
					}
					if (!is_main && images.length) {
						gallery.current = images;
					}
					setReload((reload) => !reload);
				}
			};
			reader.readAsDataURL(input.files[index]);
		}
	};

	const { ref: materialRef, autocompleteRef } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE,
		onPlaceSelected: (place) => {
			if (formData.current.address_label === "") {
				formData.current.address_label = place.formatted_address;
			}
			formData.current.address_name = place.formatted_address;
			set_address(place.geometry.location.lat(), place.geometry.location.lng());
		},
		options: {
			componentRestrictions: { country: "be" },
			types: ["geocode"],
		},
	});

	useEffect(() => {
		return () => {
			save_data();
		};
	}, []);

	useEffect(() => {
		formData.current = { ...formDataState };
	}, [formDataState]);

	return (
		<>
			<div className="create-update-center">
				<div onClick={() => navigate(-1)} className="go-back-container">
					<ArrowBackIosIcon />
					<span>Quitter l'éditeur d'événement</span>
				</div>

				<MapModal
					center={{ lat: formData.current.address_latitude, lng: formData.current.address_longitude }}
					close={() => setOpenMap(false)}
					save={(lat, lng) => {
						set_address(lat, lng);
						setOpenMap(false);
					}}
					open={openMap}
				/>

				<h1>Nouvel événement</h1>
				<div id="title" className="input-container">
					<h2>Informations</h2>
					<p>
						Complétez les premières informations pour que l'utilisateur comprenne immédiatement de quoi il
						s'agit, à quel endroit ou à quelle heure.
					</p>
					<label htmlFor="event-name">Nom de l'événement</label>
					<input
						defaultValue={formDataState.title}
						onChange={(e) => setFormDataState({ ...formDataState, title: e.target.value })}
						id="event-name"
						className="classic-text-input"
					/>
					<label htmlFor="event-organizer">Organisateur</label>
					<input
						defaultValue={user.account.title}
						disabled
						id="event-organizer"
						className="classic-text-input"
					/>
				</div>
				<div id="details" className="input-container">
					<h2>Détails</h2>
					<p>
						Donnez plus de détails à votre événement pour que l'utilisateur sache de quoi il s'agit à
						premier vu d'oeil.
					</p>
					<label htmlFor="event-intro">Texte d'introduction</label>
					<textarea
						value={formDataState.small_description}
						onChange={(e) =>
							setFormDataState({
								...formDataState,
								small_description: e.target.value,
							})
						}
						rows={3}
						id="event-intro"
						className="classic-text-area"
					></textarea>
					<label htmlFor="event-full-intro">Description complète</label>

					<div className="ck-editor-container">
						<CKEditor
							config={{
								toolbar: {
									items: [
										"heading",
										"|",
										"bold",
										"italic",
										"link",
										"bulletedList",
										"numberedList",
										"|",
										"alignment",
										"|",
										"indent",
										"outdent",
										"|",
										"blockQuote",
										"insertTable",
										"|",
										"undo",
										"redo",
									],
								},
								language: "fr",
							}}
							editor={ClassicEditor}
							data={formDataState.description}
							onReady={(editor) => {
								// You can store the "editor" and use when it is needed.
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								setFormDataState({
									...formDataState,
									description: data,
								});
							}}
						/>
					</div>
				</div>

				<div id="medias" className="input-container">
					<h2>Médias</h2>
					<p>Ajoutez des photos pour présenter votre événement</p>
					<div
						onClick={() =>
							mainImage.current ? null : document.getElementById("f-input-main-picture")?.click()
						}
						className={`file-input-container margin ${!mainImage.current ? "clickable" : ""}`}
					>
						<input
							onChange={() => get_images("f-input-main-picture", true)}
							id="f-input-main-picture"
							type="file"
							accept="image/png, image/jpeg, image/webp"
						/>
						{mainImage.current || event.main_picture ? (
							<div className="image-container">
								<div className="image-header">
									<div></div>
									<IconButton
										onClick={() => {
											mainImage.current = null;
											setReload((reload) => !reload);
										}}
									>
										<ClearIcon />
									</IconButton>
								</div>
								<img
									src={
										mainImage.current
											? mainImage.current[1]
											: generate_image_path(event.id, event.main_picture, "phone")
									}
								/>
							</div>
						) : (
							<span>Image principale</span>
						)}
					</div>
				</div>

				<div id="date-hour" className="input-container">
					<h2>Date et heure</h2>
					<p>
						Indiquez aux participants les dates de début et de fin de l'événement afin qu'ils puissent
						planifier leur présence.
					</p>
					<label htmlFor="event-start-at">Début de l'événement</label>

					<DatePicker
						allowClear={false}
						id="event-start-at"
						onChange={(date, dateString) => {
							if (
								date &&
								formDataState.end_at &&
								date.toDate().getTime() > formDataState.end_at.getTime()
							) {
								setFormDataState({
									...formDataState,
									start_at: date?.toDate(),
									end_at: date?.toDate(),
								});
							} else {
								setFormDataState({
									...formDataState,
									start_at: date?.toDate(),
								});
							}
						}}
						className="classic-date-input"
						value={formDataState.start_at ? dayjs(formDataState.start_at) : null}
						style={{ width: "300px" }}
						picker="date"
						format={"YYYY-MM-DD HH:mm"}
						showTime
					/>
					<label htmlFor="event-end-at">Fin de l'événement</label>

					<DatePicker
						allowClear={false}
						id="event-end-at"
						onChange={(date, dateString) =>
							setFormDataState({
								...formDataState,
								end_at: date?.toDate(),
							})
						}
						disabledDate={(d) => {
							const date = new Date(formDataState.start_at);
							date.setHours(0, 0, 0, 0);
							return !d || d.isBefore(date);
						}}
						className="classic-date-input"
						value={formDataState.end_at ? dayjs(formDataState.end_at) : null}
						style={{ width: "300px" }}
						picker="date"
						format={"YYYY-MM-DD HH:mm"}
						showTime
					/>
				</div>

				<div id="location" className="input-container">
					<h2>Localisation</h2>
					<p>
						Aidez les gens de la région à découvrir votre événement et indiquez aux participants où se
						présenter.
					</p>
					<label htmlFor="event-organizer">Nom du lieu</label>
					<input
						value={formDataState.address_label}
						onChange={(e) =>
							setFormDataState({
								...formDataState,
								address_label: e.target.value,
							})
						}
						id="event-organizer"
						className="classic-text-input"
					/>
					<label
						style={{
							display:
								!formData.current.address_latitude && !formData.current.address_longitude
									? "initial"
									: "none",
						}}
						htmlFor="event-organizer"
					>
						Addresse
					</label>
					<input
						style={{
							display:
								!formData.current.address_latitude && !formData.current.address_longitude
									? "initial"
									: "none",
						}}
						ref={materialRef}
						id="event-organizer"
						className="classic-text-input"
					/>
					{formData.current.address_latitude != 0.0 && formData.current.address_longitude != 0.0 && (
						<div className="address-clear">
							<Button
								onClick={() => {
									formData.current.address_label = "";
									formData.current.address_latitude = 0.0;
									formData.current.address_longitude = 0;
									setFormDataState(formData.current);
								}}
							>
								Changer l'adresse
							</Button>
							<Button onClick={() => setOpenMap(true)} className="main" type="primary">
								Modifier la position exacte
							</Button>
						</div>
					)}
				</div>
			</div>
			<CreateUpdateAside
				tips={[
					{
						title: "Titre et courte description",
						content: "Soyez concis et descriptif !",
					},
					{
						title: "Medias",
						content: "Il s'agit de l'un des points les plus importants de l'image de votre événement.",
					},
					{
						title: "Localisation",
						content:
							"Le libellé du lieu est completement libre et vous permet de donner un nom plus comprehensible pour vos invites.",
					},
				]}
				can_save={can_save}
				save={save_data}
				clean={clean}
			>
				<GeneralDataPreview main_picture={mainImage.current} event={event} data={formDataState} />
			</CreateUpdateAside>
		</>
	);
};

export default GeneralData;
