import React from "react";
import "./style.scss";

const LabelContent = ({ children, label_value, disabled }) => {
	return (
		<div className={`label-container-item ${disabled ? "disabled" : ""}`}>
			<label htmlFor={children.props.id}>{label_value}</label>
			{children}
		</div>
	);
};

export default LabelContent;
