// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participation-forms {
  padding: 30px;
  padding-top: 50px;
  width: 100%;
}
.participation-forms .form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 30px;
  align-items: stretch;
  width: 100%;
}
.participation-forms .form-container .form-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 200px;
  border-radius: var(--border-radius);
  background-color: var(--clr-surface);
  box-shadow: var(--box-shadow);
  cursor: pointer;
}
.participation-forms .form-container .form-item .form-number {
  font-size: 0.8rem;
  color: var(--clr-secondary-text);
  display: block;
  margin-bottom: 10px;
}
.participation-forms .form-container .form-item .title {
  font-weight: bold;
  font-size: 1.2rem;
}
.participation-forms .form-container .form-item.new {
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/events/participation_forms/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,iBAAA;EACA,WAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,iBAAA;EACA,oBAAA;EACA,WAAA;AACF;AACE;EACC,aAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,mCAAA;EACA,oCAAA;EACA,6BAAA;EACA,eAAA;AACH;AACG;EACC,iBAAA;EACA,gCAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAEG;EACC,iBAAA;EACA,iBAAA;AAAJ;AAGG;EACC,mBAAA;EACA,uBAAA;AADJ","sourcesContent":[".participation-forms {\n\tpadding: 30px;\n\tpadding-top: 50px;\n\twidth: 100%;\n\n\t.form-container {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\tflex-wrap: wrap;\n\t\tgap: 20px;\n\t\tpadding-top: 30px;\n\t\talign-items: stretch;\n\t\twidth: 100%;\n\n\t\t.form-item {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tpadding: 15px;\n\t\t\tmin-width: 200px;\n\t\t\tborder-radius: var(--border-radius);\n\t\t\tbackground-color: var(--clr-surface);\n\t\t\tbox-shadow: var(--box-shadow);\n\t\t\tcursor: pointer;\n\n\t\t\t.form-number {\n\t\t\t\tfont-size: 0.8rem;\n\t\t\t\tcolor: var(--clr-secondary-text);\n\t\t\t\tdisplay: block;\n\t\t\t\tmargin-bottom: 10px;\n\t\t\t}\n\n\t\t\t.title {\n\t\t\t\tfont-weight: bold;\n\t\t\t\tfont-size: 1.2rem;\n\t\t\t}\n\n\t\t\t&.new {\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: center;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
