// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-creation-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/contexts/event-creation-context.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,WAAA;AACD","sourcesContent":[".event-creation-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
