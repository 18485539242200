export const generate_image_path = (event_id, base, type, organizer = false) => {
	if (base == "") {
		return (
			`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_CLOUD_BUCKET}/o/` +
			`base/empty.webp`.replace(/(\/)/g, "%2F") +
			"?alt=media"
		);
	}
	const path = `${organizer ? "organizers" : "events"}/${event_id}/${base}-${type}.webp`;
	const downloadUrl =
		`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_CLOUD_BUCKET}/o/` +
		path.replace(/(\/)/g, "%2F") +
		"?alt=media";
	return downloadUrl;
};
