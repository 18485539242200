import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import axios from "../../../../../services/axios";
import EventCreationContext from "contexts/EventCreationContext";
import { Popover, Switch } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import Modal from "components/main/modal/Modal";
import PromoCodeFormModal from "./promo_code_form_modal/PromoCodeFormModal";

const PromoCode = ({ tickets }) => {
	const { event } = useContext(EventCreationContext);
	const [promoCode, setPromoCode] = useState({});
	const [selected, setSelected] = useState({
		item: null,
		new: false,
	});

	const load_promo_codes = async () => {
		const promo_codes = await axios
			.get(`api/events/${event.id}/promo-code`)
			.then((e) => e.data.sort((a, b) => a.id - b.id))
			.catch((e) => null);
		if (promo_codes) {
			const promo_codes_dict = {};
			promo_codes.forEach((promo_code) => {
				if (promo_codes_dict[promo_code.code]) {
					promo_codes_dict[promo_code.code].push(promo_code);
				} else {
					promo_codes_dict[promo_code.code] = [promo_code];
				}
			});
			setPromoCode(promo_codes_dict);
		}
	};

	const save_promo_codes = async (codes) => {
		const resp = await axios
			.put(`api/events/${event.id}/promo-code/${encodeURIComponent(codes[0].code)}/bulk`, codes)
			.then((e) => e.data)
			.catch((e) => null);
		if (resp) {
			load_promo_codes();
			setSelected({
				item: null,
				new: false,
			});
		}
	};

	const update_promo_code_status = async (code, status) => {
		const resp = await axios
			.put(`api/events/${event.id}/promo-code/${encodeURIComponent(code)}/${status}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (resp) {
			load_promo_codes();
		}
	};

	useEffect(() => {
		load_promo_codes();
	}, [event]);

	return (
		<div className="promo-code-container">
			<PromoCodeFormModal
				save={save_promo_codes}
				selected={selected}
				close={() => setSelected({ item: null, new: false })}
				tickets={tickets}
			/>
			<div
				onClick={() => {
					setSelected({
						item: [
							{
								event: event.id,
								tickets: "all",
								target: "",
								code: "",
								ticket_limit: -1,
								discount: 0,
								discount_type: "percent",
								start_at: new Date(new Date().setMinutes(0, 0, 0)),
								end_at: null,
							},
						],
						new: true,
					});
				}}
				className="global-fill-btn"
			>
				Nouveau code promotionnel
			</div>
			<div className="promo-code-wrapper">
				{Object.keys(promoCode).map((key, id) => {
					let tickets_str = "";
					let linked = [];
					if (promoCode[key].length > 1) {
						promoCode[key].map((e) => (tickets_str += e.tickets.substring(1)));
						tickets_str = tickets_str.substring(0, tickets_str.length - 1);
						linked = tickets_str.split("|").map((e) => parseInt(e));
					} else {
						if (promoCode[key][0].tickets != "all") {
							linked = [
								parseInt(promoCode[key][0].tickets.substring(1, promoCode[key][0].tickets.length - 1)),
							];
						} else {
							linked = tickets.map((e) => e.id);
						}
					}

					let can_click = true;

					return (
						<div
							onClick={() => {
								if (can_click) {
									setSelected({
										item: promoCode[key],
										new: false,
									});
								}
								can_click = true;
							}}
							key={id}
							className="promo-code-item"
						>
							<div className="column">
								<span className="code">
									Code: <span className="title">{key}</span>
								</span>
								<Popover
									content={
										<div>
											{linked.map((ticket, id) => {
												const ticket_data = event.tickets.find((t) => t.id == ticket);
												if (!ticket_data) return null;
												return (
													<p key={id}>
														<LinkOutlined
															style={{
																marginRight: "5px",
																color: "#4589ff",
															}}
														/>
														{ticket_data.title}
													</p>
												);
											})}
										</div>
									}
									title="Tickets liés"
								>
									<span className="linked-count">
										<LinkOutlined /> {linked.length}
									</span>
								</Popover>
							</div>

							<Switch
								value={promoCode[key][0].status === "active"}
								onChange={() => {
									update_promo_code_status(
										promoCode[key][0].code,
										promoCode[key][0].status === "active" ? "inactive" : "active"
									);
									can_click = false;
								}}
							/>
						</div>
					);
				})}
				{Object.keys(promoCode).length == 0 && <p>Améliorer vos ventes avec des codes promo.</p>}
			</div>
		</div>
	);
};

export default PromoCode;
