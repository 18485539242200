// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#financial-data .input-row {
  width: 100%;
}
#financial-data .input-row > .ant-col {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/screens/account_settings/financial_data/style.scss"],"names":[],"mappings":"AACC;EACC,WAAA;AAAF;AAEE;EACC,UAAA;AAAH","sourcesContent":["#financial-data {\n\t.input-row {\n\t\twidth: 100%;\n\n\t\t> .ant-col {\n\t\t\twidth: 50%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
