import { Button } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../settings/default_view/style.scss";
import { LoadingButton } from "@mui/lab";

const DefaultView = ({ children, visible, save, reset, is_valid, hide_navbar, loading = false }) => {
	const location = useLocation();

	return (
		<div id="settings-base-view" className={`${hide_navbar ? "hide-nav" : ""}`}>
			{hide_navbar ? null : (
				<ul className={`account-navigation ${visible === "always" ? "full" : "aside"}`}>
					<li className={location.pathname === "/account-settings" ? "active hov" : "hov"}>
						<Link to="/account-settings">
							<div className="account-nav-elem">
								<i className="fas fa-cog"></i>
								<div>
									<span>Paramètres généraux</span>
									<p>Nom du compte, ...</p>
								</div>
							</div>
						</Link>
					</li>
					<li className={location.pathname === "/account-settings/members" ? "active hov" : "hov"}>
						<Link to="/account-settings/members">
							<div className="account-nav-elem">
								<i className="fas fa-users"></i>
								<div>
									<span>Membres</span>
									<p>Gestion des rôles, droits d'accès...</p>
								</div>
							</div>
						</Link>
					</li>
					<li className={location.pathname === "/account-settings/finance" ? "active hov" : "hov"}>
						<Link to="/account-settings/finance">
							<div className="account-nav-elem">
								<i className="fas fa-users"></i>
								<div>
									<span>Finances</span>
									<p>Compte bancaire, virements...</p>
								</div>
							</div>
						</Link>
					</li>
				</ul>
			)}
			{!save ? (
				<>{children}</>
			) : (
				<>
					{children}
					<div className="save-changes">
						<Button onClick={reset} variant="text">
							Réinitialiser
						</Button>
						<LoadingButton loading={loading} onClick={save} disabled={!is_valid()} variant="contained">
							Sauvegarder
						</LoadingButton>
					</div>
				</>
			)}
		</div>
	);
};

export default DefaultView;
