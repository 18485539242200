import React, { useContext, useEffect, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import { DataGrid } from "@mui/x-data-grid";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import EmptyView from "components/main/empty_view/EmptyView";
import { useDemoData } from "@mui/x-data-grid-generator";
import CellBoolean from "components/table_components/CellBoolean";
import CellLink from "components/table_components/CellLink";
import CellDate from "components/table_components/CellDate";
import PassPassTable from "components/table/table/PassPassTable";

const columns = [
	{
		width: 120,
		headerName: "Entry id",
		field: "str_id",
	},
	{
		width: 140,
		headerName: "Ticket ID",
		field: "ticket_str_id",
		renderCell: (e) => (
			<CellLink label={e.value} to={`/events/${e.row.event}/tickets/${e.row.ticket}`} />
		),
	},
	{
		width: 100,
		headerName: "Is enter",
		field: "is_in",
		renderCell: (e) => <CellBoolean labels={["Entry IN", "Entry OUT"]} value={e.value} />,
	},
	{
		width: 200,
		headerName: "Validation date",
		field: "validated_at",
		renderCell: (e) => <CellDate date={e.value} />,
	},
];

const EventEntries = () => {
	const { event_id } = useParams();
	const navigate = useNavigate();
	const { event, load_entries } = useContext(CurrentEventContext);

	useEffect(() => {
		if (event?.id) {
			load_entries(parseInt(event_id));
		}
	}, [event]);

	if (!event?.entries?.length) {
		return (
			<DefaultView>
				<EmptyView
					height="calc(100vh - 120px)"
					title="No entries"
					description="On this page you will see the list of entrances and exits for your event."
				/>
			</DefaultView>
		);
	}

	return (
		<DefaultView>
			<div id="event-participants">
				<PassPassTable
					columns={columns.map((column, index) => {
						column.order = index;
						return column;
					})}
					pageSizeOptions={[10, 20, 50, 100]}
					title="Entrées"
					data={event?.entries ? [...event.entries].sort((a, b) => b.id - a.id) : []}
				/>
			</div>
		</DefaultView>
	);
};

export default EventEntries;
