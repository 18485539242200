import React from "react";
import "./style.scss";
import { generate_image_path } from "helpers/images";
import { format_date, format_time_range } from "helpers/format";
import { Link } from "react-router-dom";
import copy from "../../../assets/dashboard/copy.svg";
import edit from "../../../assets/dashboard/edit.svg";
import view from "../../../assets/dashboard/view.svg";
import EmptyPicture from "../../../assets/dashboard/empty-picture.png";
import { Skeleton } from "@mui/material";

const DashboardNextEvent = ({ event }) => {
	if (!event) {
		return (
			<div className="next-event-box">
				<div className="next-event-left">
					<div className="img-container empty">
						<img src={EmptyPicture} />
					</div>
					<span className="title empty"></span>
					<span className="date empty"></span>
					<span className="address empty"></span>
				</div>
				<div className="next-event-right">
					<div>
						<span className="no-events">Aucun événement à venir...</span>
						<Link to="/create-event" className="classic-black-span-btn">
							Créer un événement
						</Link>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="next-event-box">
			<Link to={`/events/${event.id}`} className="next-event-left">
				<div className="img-container">
					<img src={generate_image_path(event.id, event.main_picture, "phone")} />
				</div>
				<span className="title">{event.title}</span>
				<span className="date">
					{format_date(event.start_at)} | {format_time_range(event.start_at, event.end_at)}
				</span>
				<span className="address">{event.address_label}</span>
			</Link>

			<div className="next-event-right">
				<div className="ticket-sold">
					<span className="sold-count title">{event.tickets_sold}</span>
					<span>Inscrits</span>
				</div>
				<div className="buttons-container">
					<div
						className="button"
						onClick={() =>
							navigator.clipboard?.writeText(
								`${process.env.REACT_APP_SITE_URL}/events/${event.id}/${encodeURIComponent(
									event.title.replaceAll(" ", "-")
								)}`
							)
						}
					>
						<img src={copy} />
						Copier le lien
					</div>
					<Link
						className="button"
						target="_blank"
						to={`${process.env.REACT_APP_SITE_URL}/events/${event.id}/${encodeURIComponent(
							event.title.replaceAll(" ", "-")
						)}`}
					>
						<img src={view} />
						Afficher
					</Link>
					<Link className="button" to={`/update-event/${event.id}`}>
						<img src={edit} />
						Modifier
					</Link>
				</div>
			</div>
		</div>
	);
};

export default DashboardNextEvent;
